import BRU from '../assets/locations/brussels.png';
import LDN from '../assets/locations/london.png';
import PRG from '../assets/locations/prague.png';

const locations = [
  {
    city: 'London',
    address: '33 Cannon Street\nEC4M 5SB,\nLondon\nUnited Kingdom',
    image: LDN,
  },
  {
    city: 'Brussels',
    address: '1 Boulevard Roi Albert II 1210\nBrussels\nBelgium',
    image: BRU,
  },
  {
    city: 'Prague',
    address: 'International Business Center\nPobřežní 620/3 Praha 8\nCzech Republic',
    image: PRG,
    },
];


export default locations;
