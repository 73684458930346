import React from 'react';
import { Link } from 'react-router-dom';
// import YoutubeLogo from '../assets/youtube.svg';
import LinkedinLogo from '../assets/linkedin.svg';
import EuroclearLogo from '../assets/euroclearLogo.png';
import '../index.css';
import QR from '../assets/Q Logo Red.svg';
import QuantessenceR from '../assets/Quantessence Logo Red.svg';

const footerLinks = [
  { to: '/privacypolicy', label: 'Privacy & Cookie Policy' },
  // { to: '/employeepolicy', label: 'Employee Policy' },
  { to: '/legalnotice', label: 'Legal Notice' },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='flex flex-col justify-center items-center p-6 md:p-12 gap-6 md:gap-12 border-t border-2px'>
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col justify-center items-center gap-6'>
          <img className='h-12' src={QR} alt='logo' />
          <div className='flex flex-col items-center gap-2'>
            <img className='h-6' src={QuantessenceR} alt='euroclearcompany' />
            <p className='font-medium text-xl text-[#DF3C44]'>
              A Euroclear company
            </p>
          </div>
        </div>
        <div className='flex justify-center gap-6'>
          {/* <a
            href='https://www.youtube.com/@quantessenceltd.3054'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={YoutubeLogo} alt='Youtube' className='h-8 w-8' />
          </a> */}
          <a
            href='https://www.linkedin.com/company/quantessence-financial-ltd/mycompany/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={LinkedinLogo} alt='Linkedin' className='h-8 w-8' />
          </a>
          <a
            href='https://www.euroclear.com/en.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={EuroclearLogo} alt='Euroclear' className='w-10' />
          </a>
        </div>
        <div className='flex flex-wrap justify-center gap-2 md:gap-6'>
          {footerLinks.map((link, index) => (
            <Link
              key={index}
              className='text-primary-blue text-lg'
              to={link.to}
            >
              {link.label}
            </Link>
          ))}
        </div>
      </div>
      <div className='flex justify-center pt-4 w-full border-t border-2px text-center'>
        <p className='font-light text-sm text-primary-blue'>
          Ⓒ {currentYear} Quantessence Technology Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;