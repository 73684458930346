import React from 'react';
import ArticleDetail from '../components/News/ArticleDetail';

function Article() {
  return (
    <>
      <ArticleDetail />
    </>
  );
}

export default Article;
