import React from 'react';
import Title from '../Title';
import Stepper from './Stepper';

const StepperSection = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Design • Develop • Deliver: Your Easy Path to Success</Title>
      <Stepper />
    </div>
  );
}

export default StepperSection;