import React from 'react';
import { useParams, Link } from 'react-router-dom';

import Title from '../Title.jsx';
import Button from '../Button';
import jobs from '../../data/jobs.js';

import '../../index.css';

const JobDetail = () => {
  const { slug } = useParams();
  const job = jobs.find((job) => job.slug === slug);

  if (!job) {
    return <div>Job not found</div>;
  }

  const {
    location,
    intro,
    position,
    role,
    experience,
    qualifications,
    additional_info,
    jobUrl,
  } = job.description;

  const renderDetails = (label, content) => (
    <p className='flex flex-col md:flex-row gap-2'>
      <p className=' text-lg lg:text-xl font-medium text-primary-red'>
        {label}:
      </p>
      <span>{content}</span>
    </p>
  );

  const renderList = (label, items) => (
    <>
      <p className=' text-lg lg:text-xl font-medium text-primary-red'>
        {label}:
      </p>
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </>
  );

  return (
    <div className='flex flex-col items-center justify-center p-6 lg:p-12 gap-6 lg:gap-12 text-primary-blue max-w-[1600px] mx-auto w-full'>
      <Title>{job.title}</Title>
      <div className='flex flex-col w-full gap-6 text-justify text-lg lg:text-xl font-light leading-8'>
        {renderDetails('Location', location)}
        <p>{intro}</p>
        {renderDetails('Position', position)}
        {renderList('Role', role)}
        {renderList('Experience & Skills', experience)}
        {renderList('Qualifications', qualifications)}
        <p>{additional_info}</p>
        <Link to={jobUrl} target='_blank'>
          <Button>View Job Posting</Button>
        </Link>
        <p className='text-base text-primary-blue'>
        For more information on how Quantessence Financial processes your personal data please refer to our {' '}
          <Link to='/employeepolicy' className='text-primary-red'>
            Employee Privacy Notice
          </Link>.
        </p>
      </div>
    </div>
  );
};

export default JobDetail;