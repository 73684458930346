import React from 'react';
import JobDetail from '../components/Careers/JobDetail.jsx';

function Job() {
  return (
    <JobDetail />
  );
}

export default Job;
