import React, { useState } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import markerIcon from '../../assets/location-pin.svg';
import {createUrl} from '../../utils.js'

const containerStyle = {
  width: '100%',
  height: '500px',
};

const location1 = {
  lat: 50.8563626,
  lng: 4.3534095,
  label: '1 Boulevard du Roi Albert II',
};

const location2 = {
  lat: 51.5127502,
  lng: -0.0998,
  label: '33 Cannon street EC4M 5SB',
};

const location3 = {
  lat: 50.0931152,
  lng: 14.4384028,
  label: 'International Business Center Pobřežní',
};

const endpoint = createUrl('/GOOGLE-MAPS-API-KEY');
console.log("GET TO " + endpoint);
const response = await fetch(endpoint);
const googleMapsApiKey = await response.text();

const ContactMap = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
  });

  const [selectedLocation, setSelectedLocation] = useState(null);

  if (loadError) {
    console.error('Error loading Google Maps API:', loadError);
    return <div>Error loading Google Maps</div>;
  }

  const customIcon = {
    url: markerIcon,
    scaledSize:
      isLoaded && window.google && window.google.maps
        ? new window.google.maps.Size(40, 40)
        : null,
    size:
      isLoaded && window.google && window.google.maps
        ? new window.google.maps.Size(40, 40)
        : null,
    anchor:
      isLoaded && window.google && window.google.maps
        ? new window.google.maps.Point(20, 40)
        : null,
  };

  return (
    <div className='w-full lg:w-1/2 lg:order-1 order-2'>
      <div className='w-full h-80 lg:h-[500px] rounded overflow-hidden'>
        {!isLoaded ? (
          <div className='w-full h-full bg-gray-200 animate-pulse' />
        ) : (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={15}
            onLoad={(map) => {
              if (window.google && window.google.maps) {
                const bounds = new window.google.maps.LatLngBounds();
                bounds.extend(location1);
                bounds.extend(location2);
                bounds.extend(location3);
                map.fitBounds(bounds);
              }
            }}
          >
            <Marker
              position={location1}
              icon={customIcon}
              onClick={() => setSelectedLocation(location1)}
            />
            <Marker
              position={location2}
              icon={customIcon}
              onClick={() => setSelectedLocation(location2)}
            />
            <Marker
              position={location3}
              icon={customIcon}
              onClick={() => setSelectedLocation(location3)}
            />
            {selectedLocation && (
              <InfoWindow
                position={{
                  lat: selectedLocation.lat,
                  lng: selectedLocation.lng,
                }}
                onCloseClick={() => setSelectedLocation(null)}
              >
                <h2>{selectedLocation.label}</h2>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
      </div>
      <div className='flex flex-col gap-2 py-4'>
        <p className='text-xl lg:text-2xl font-medium text-primary-red'>
          Corporate Headquarters
        </p>
        <p className='text-lg lg:text-xl font-light text-primary-blue'>
          1, Boulevard du Roi Albert II 1210 | Brussels, Belgium
        </p>
      </div>
    </div>
  );
};

export default React.memo(ContactMap);