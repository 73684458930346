import React from 'react';
import AboutCompany from '../components/About/AboutCompany';
import Team from '../components/About/Team';
import Locations from '../components/About/Locations';

function Company() {
  return (
    <>
      <AboutCompany />
      <Team />
      <Locations />
    </>
  );
}

export default Company;
