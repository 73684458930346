import React from 'react';
import Title from '../Title.jsx';
import legaldata from '../../data/legalNotice.js';

const LegalNotice = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12 text-center max-w-[1600px] mx-auto w-full'>
      <Title> Legal Notice</Title>
      <p className='text-lg lg:text-xl font-light text-gray-500'>
        {`Published by `}
        <span className='font-medium text-primary-red'> Quantessence </span>
        {` on 13th November 2024 `}
      </p>
      <div className='w-full text-primary-blue text-justify'>
        {legaldata.sections.map((section, index) => (
          <div key={index} className='mb-6'>
            <p className='text-xl lg:text-2xl font-medium'>{section.heading}</p>
            {section.paragraphs.map((paragraph, pIndex) => (
              <p
                key={pIndex}
                className='text-lg lg:text-xl font-light'
                dangerouslySetInnerHTML={{ __html: paragraph }}
              ></p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LegalNotice;