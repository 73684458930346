import React from 'react';
import SiteMap from '../components/Policies/SiteMap';

function Employee() {
  return (
    <>
      <SiteMap />
    </>
  );
}

export default Employee;