import React from 'react';

const LocationsCards = ({ location }) => {
  return (
    <div className='flex flex-col items-center w-full lg:w-96 h-[400px] text-center'>
      <div className='group rounded shadow-lg flex flex-col h-full w-full relative transition-all duration-300 hover:translate-y-[-10px]'>
        <div
          className='relative flex items-center justify-center w-full h-[400px] rounded shadow-lg bg-no-repeat bg-cover bg-center transition-transform duration-[600ms] transition-filter duration-[1000ms]'
          style={{ backgroundImage: `url(${location.image})` }}
        >
          <div className='absolute bottom-0 p-6 w-full text-center text-xl lg:text-2xl font-medium text-white overlay bg-black bg-opacity-50 text-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out'>
            <p>{location.city}</p>
          </div>
        </div>
        <div className='hidden p-6 text-lg lg:text-xl font-light text-primary-blue opacity-0 translate-y-4 group-hover:block group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300'>
          <p>
            {location.address.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LocationsCards;