import React from 'react';
import values from '../../data/values';
import Card from '../Card';

const ValueSection = () => {
  return (
    <div className='flex flex-col lg:flex-row max-w-[1600px] mx-auto w-full'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
        {values.map((value, index) => (
          <Card
            key={index}
            icon={value.icon}
            title={value.title}
            description={value.description}
          />
        ))}
      </div>
    </div>
  );
};

export default ValueSection;