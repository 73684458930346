export function createUrl(relativeUrl) {
	let baseUrl = process.env.REACT_APP_SERVER_ENDPOINT
	if(baseUrl.endsWith("/")){
		baseUrl = baseUrl.slice(0,-1)
	}
    if(!relativeUrl.startsWith("/")){
		relativeUrl = "/" + relativeUrl;
	}
	// console.log("BASE URL: " + baseUrl);
	// console.log("RELATIVE URL: " + relativeUrl);
	return baseUrl + relativeUrl;
}