import React from 'react';
import Title from '../Title';
import ServiceCard from '../Services/ServiceCard';

const ServiceSection = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Our Services</Title>
      <ServiceCard />
    </div>
  );
}

export default ServiceSection;