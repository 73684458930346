import Connections from '../assets/services/connections.jpg';
import Calculations from '../assets/services/calculations.jpg';
import STP from '../assets/services/order-management.jpg';
import DataRepository from '../assets/services/custom-data.jpg';


let services = [
  {
    id: 1,
    title: 'Connections',
    description:'Euroclear Quantessence is your gateway to the market, connecting you with the providers and counterparties of your choice. Our open architecture empowers you to seamlessly add or switch providers with no additional development required on your end.',
    image: Connections,
  },
  {
    id: 2,
    title: 'Calculations',
    description:'Our calculation engine powers the algorithms behind your products. If it can be calculated, we can run it.',
    image: Calculations,
  },
  { 
    id: 3,
    title: 'Order Management',
    description: 'We ensure seamless order transmission, routing orders—whether generated by our algorithms or by you—to your chosen financial institution with transparent straight-through processing and reconciliation.',
    image: STP,
  },
  {
    id: 4,
    title: 'Custom Data Insights',
    description: 'We offer transparent access to your data for monitoring and analytics, effortlessly delivered through customisable dashboards and bespoke reports.',
    image: DataRepository,
  },
];

export default services;