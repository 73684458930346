import React from 'react';
import LegalNotice from '../components/Policies/LegalNotice';

function Legal() {
  return (
    <>
      <LegalNotice />
    </>
  );
}

export default Legal;