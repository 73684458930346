import React from 'react';

const Card = ({ title, description, width = 'w-auto', height = 'h-auto' }) => {
  return (
    <div
      className={`${width} ${height} h-full flex flex-col justify-between bg-white rounded p-4 shadow-lg transform transition-transform hover:scale-105`}
    >
      <div className='flex flex-col flex-grow justify-start'>
        <p className='min-h-16 text-center text-xl lg:text-2xl font-medium text-primary-red'>
          {title}
        </p>
        <p className='text-justify md:text-center text-lg lg:text-xl font-light leading-8 text-primary-blue'>
          {description}
        </p>
      </div>
    </div>
  );
};

export default Card;