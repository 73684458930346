import React from 'react';
import Title from '../Title.jsx';
import cookielist from '../../data/cookielist.js';

const CookieList = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12 text-center max-w-[1600px] mx-auto w-full'>
      <Title>List of Cookies</Title>
      <p className='text-lg lg:text-xl font-light text-gray-500'>
        {`Published by `}
        <span className='font-medium text-primary-red'>Quantessence</span>
        {` on 13th November 2024 `}
      </p>
      <table className='table-auto w-full text-left'>
        <thead>
          <tr>
            <th className='border p-2 text-left text-lg lg:text-xl font-medium text-white bg-primary-red w-1/4'>ID</th>
            <th className='border p-2 text-left text-lg lg:text-xl font-medium text-white bg-primary-red w-1/4'>Domain</th>
            <th className='border p-2 text-left text-lg lg:text-xl font-medium text-white bg-primary-red w-1/4'>Duration</th>
            <th className='border p-2 text-left text-lg lg:text-xl font-medium text-white bg-primary-red w-1/4'>Description</th>
          </tr>
        </thead>
        <tbody>
          {cookielist.map((cookie) => (
            <tr key={cookie.id}>
              <td className='px-2 py-2 border text-primary-blue'>
                {cookie.id}
              </td>
              <td className='px-2 py-2 border text-primary-blue'>
                <a
                  href={`https://${cookie.domain}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 hover:underline pointer'
                >
                  {cookie.domain}
                </a>
              </td>
              <td className='px-2 py-2 border text-primary-blue'>
                {cookie.duration}
              </td>
              <td className='px-2 py-2 border text-primary-blue'>
                {cookie.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CookieList;