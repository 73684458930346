const aboutCompany = {
  content: (
    <>
      <p>
        Quantessence provides financial institutions with world-class
        technological support for a range of savings and investment products. In
        particular, the platform is used by some of the world’s leading
        insurers, banks, and asset managers to offer individualised,
        customisable solutions to their customers, ranging from protected
        solutions to a variety of quantitative asset allocation strategies.
      </p>
      <p>
        Resource requirements are light, costs are low, and time to market is
        fast. Euroclear Quantessence is an open architecture platform,
        connecting clients to the providers of their choice. The platform is an
        integral part of Euroclear’s core infrastructure.
      </p>
      <p>
        The{' '}
        <a
          href='https://www.euroclear.com/'
          rel='noreferrer'
          target='_blank'
          className='text-primary-red'
        >
          Euroclear group
        </a>{' '}
        is the financial industry’s trusted provider of post-trade services with
        more than €37 Trillion assets under custody, processing €837 Trillion
        securities per annum. The group ensures the efficiency of markets and
        focuses on client and investor safety. Euroclear Bank, the leading
        international CSD, is rated AA+/AA.
      </p>
      <p>
        Quantessence is authorised and regulated by the Financial Services and Markets
        Authorities (FSMA) in Belgium and by the Financial Conduct Authority (FCA)
        in the UK (firm reference number 975752). Details of the registration
        can be checked on the{' '}
        <a
          href='https://register.fca.org.uk/s/'
          target='_blank'
          rel='noreferrer'
          className='text-blue-600'
        >
          FCA’s website
        </a>
        .
      </p>
    </>
  ),
};

export default aboutCompany;