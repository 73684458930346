import React from 'react';
import { useParams } from 'react-router-dom';
import ProductDescription from '../components/Illustrations/ProductDescription';
import ProductFeatures from '../components/Illustrations/ProductFeatures';

// import Animation from '../components/Illustrations/Animation';
import ProductVideo from '../components/Illustrations/ProductVideo';
import ProdSupport from '../components/Illustrations/ProdSupport';
import Role from '../components/Illustrations/Role';

import products from '../data/products';

function Product() {
  const { slug } = useParams(); 
  const product = products.find((product) => product.slug === slug); 

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
      <ProductDescription />
      <ProductFeatures />
      {/* <Animation /> */}
      <ProductVideo videoUrl={product.videoUrl} />
      <Role />
      <ProdSupport />
    </>
  );
}

export default Product;