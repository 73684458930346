import React from 'react';
import EmployeePolicy from '../components/Policies/EmployeePolicy';

function Employee() {
  return (
    <>
      <EmployeePolicy />
    </>
  );
}

export default Employee;