import React from 'react';

import ContactSection from '../components/Contact/ContactSection';

function Contact() {
  return (
    <>
      <ContactSection />
    </>
  );
}

export default Contact;
