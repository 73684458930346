import { FaLinkedin } from 'react-icons/fa';
import peter from '../assets/team/PeterDeClercq.jpg';
import wim from '../assets/team/WimHautekiet.jpg';
import olly from '../assets/team/OllyBuxton.jpg';
import olaf from '../assets/team/OlafSchmeink.png';
import pavel from '../assets/team/PavelPluhacek.jpg';
import cecile from '../assets/team/CecileVanHentenryck.png';
import pierre from '../assets/team/PierreYvesGoemans.jpg';

const team = [
  {
    id: 1,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/ukpeterdeclercq/',
    name: 'Peter De Clercq',
    title: 'Chief Executive Officer',
    description: (
      <>
        Peter is the company’s Chief Executive. He sits on the Board and chairs
        the Management Committee.
        <br />
        <br />
        Peter has over 30 years' experience in financial markets. Before
        founding Quantessence, he was Managing Director at Barclays Capital,
        where he worked with major financial institutions on strategic projects,
        including the bank’s pioneering iCPPI/iTIPP project. Prior to Barclays,
        Peter led Benelux solutions sales for Goldman Sachs and held various
        senior roles in fixed income trading and sales at JPMorgan from 1991 to
        2002. He began his career in the markets division of KBC in Belgium and
        has also worked for the United Nations in Maputo, Mozambique, overseeing
        financial management for development projects.
        <br />
        <br />
        Peter holds a <em>Maîtrise en Administration et en Gestion (MBA)</em> in
        finance from IAG at UCL, Louvain-la-Neuve (Belgium), as well as an MA in
        Philology from the University of Leuven (Belgium).
      </>
    ),
    email: 'peter.declercq@quantessence.com',
    image: peter,
  },
  {
    id: 2,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/schmeink/',
    name: 'Olaf Schmeink',
    title: 'Chief Operating Officer',

    description: (
      <>
        With over 25 years of experience in the financial industry, Olaf joined
        Quantessence in July 2024.
        <br />
        <br />
        Prior to joining Quantessence, Olaf was Head of Enterprise Solutions
        EMEA at a professional services provider, where he introduced managed
        service solutions for global financial organisations.
        <br />
        At UBS, he was Head of Business Change Management for Group Legal,
        overseeing the strategic transformation of legal operations with a
        multi-million-pound transformation budget, which improved the efficiency
        of 800 legal professionals worldwide.
        Throughout his career, he has held various executive positions in
        consulting and professional services organisations, focusing on large
        financial institutions.
        <br />
        <br />
        Olaf holds a Master of Business Administration from James Madison
        University (USA), a <em>Diplôme</em> from <em>École de Commerce</em>
        (France), and a<em>Diploma</em> in Information Management from European
        Business School (Germany).
      </>
    ),
    email: 'olaf.schmeink@quantessence.com',
    image: olaf,
  },
  {
    id: 3,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/wim-hautekiet-8034632/',
    name: 'Wim Hautekiet',
    title: 'Chief Risk & Compliance Officer',
    description: (
      <>
        Wim is Chief Risk and Compliance Officer and member of the Management Committee. 
        He has served on the Board since 2015, first as a non-executive director, and at the end of 2021 he transitioned to his current executive role when Quantessence Financial SA was authorized by the FSMA to conduct business from Belgium.
        <br />
        <br />
        He started his career at the Brussels bar (Linklaters) and went on to serve as General Counsel, regionally for BNY Mellon (London) and JP Morgan (London) and globally for Clearstream (Luxembourg) and Dexia (Paris). From 2012 to 2015, he served as CEO of The Bank of New York Mellon SA.
        <br />
        <br />
        Wim holds a Lic. Iuris from the University of Leuven (Belgium) and a LL.M. from the University of Illinois (USA), as well as Postgraduate Diplomas in Financial Management from Solvay Business School (Belgium) and INSEAD (France).
      </>
    ),
    email: 'wim.hautekiet@quantessence.com',
    image: wim,
  },
  {
    id: 4,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/pavel-pluhacek-5a65214/',
    name: 'Pavel Pluhacek',
    title: 'Lead Developer',
    image: pavel,
    description: (
      <>
        Pavel is the Technology Lead at Quantessence. He is responsible for the
        design and operations of the Euroclear Quantessence Platform.
        <br />
        <br />
        With over 15 years of experience in software development within the
        financial industry, Pavel has contributed to major projects, including
        the successful launch of individualised CPPI trading at Barclays
        Investment Bank in 2012. He joined Quantessence in 2016 and has led the
        development team since then.
        <br />
        <br />
        Pavel holds a Master’s degree in Computer Science, specialising in
        systems programming and compiler design.
      </>
    ),
    email: 'pavel.pluhacek@quantessence.com',
  },

  {
    id: 5,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/olly-buxton-34595a23b/',
    name: 'Olly Buxton',
    title: 'General Counsel',
    description: (
      <>
        Olly has more than 30 years’ legal experience in the international
        capital markets. Over that time, he has specialised in secured lending,
        structured credit, structured notes, commodities, equity financing,
        collateral optimisation, prime brokerage, energy transition and asset
        financing.
        <br />
        <br />
        Prior to joining Quantessence, Olly was General Counsel at Tramontana
        Asset Management Limited and, before that, an in-house lawyer at UBS,
        Barclays Investment Bank and Goldman Sachs International, and in private
        practice at Linklaters in London and Kensington Swan in Wellington.
        <br />
        <br />
        Olly holds a Bachelor of Laws with First Class Honours from the
        University of Canterbury in Christchurch, New Zealand, and is admitted
        as a barrister and solicitor in New Zealand and as a solicitor in
        England and Wales.
      </>
    ),
    email: 'olly.buxton@quantessence.com',
    image: olly,
  },
  {
    id: 6,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/pierre-yves-g-777742/',
    name: 'Pierre Yves Goemans',
    title: 'Chairman of the Board',
    description: (
      <>
        Currently, Pierre Yves holds the position of (non-executive) Director of
        Euroclear Global Collateral.
        <br />
        Previously, he was head of commercial, in charge of worldwide commercial
        development, marketing, client administration, and client-related IT
        projects rollout at Group level.
        <br />
        <br />
        Pierre Yves joined Euroclear in 2005 to run product management for the
        group. He was responsible for selecting markets, instruments, and client
        types as well as Euroclear’s pricing policy.
        <br />
        <br />
        Prior to joining Euroclear, Pierre-Yves fulfilled senior positions in
        the asset management industry. Initially at Fortis Investment Management
        where he was a member of the management committee, and subsequently at
        Invesco where Pierre Yves was head of business development for Western
        Europe and country manager for France.
        <br />
        Pierre Yves started his career in global markets at JPMorgan in 1986 and
        worked for McKinsey from 1989 to 1994.
        <br />
        Pierre Yves is a civil engineer from the Université Catholique de
        Louvain where he has also obtained a postgraduate degree in management
        with Highest Honours.He holds an MBA from the University of Chicago.
      </>
    ),
    email: 'pierreyves.goemans@euroclear.com',
    image: pierre,
  },
  {
    id: 7,
    icon: <FaLinkedin />,
    linkedin: 'https://www.linkedin.com/in/cecile-v-5193519/',
    name: 'Cécile Van Hentenryck',
    title: 'Director',
    description: (
      <>
        Cecile is Head of Corporate Finance Director at Euroclear heading
        company emerging business initiatives.
        <br />
        <br />
        She holds in this capacity various positions and Board mandates in the
        group.
        <br />
        Before joining Euroclear in 2008, she worked during 8 years at PwC in
        financial audit services and M&A advisory services in Belgium and
        Canada. Cecile holds a Master's degree, cum laude, in Business
        Engineering from the Solvay Brussels School of Economics and Management.
      </>
    ),
    email: 'cecile.vanhentenryck@euroclear.com',
    image: cecile,
  },
];

export default team;