import React, { useState } from 'react';
import Title from '../Title';
import Cards from '../Cards';
import jobs from '../../data/jobs';

const CareersSection = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const categories = ['All', 'Finance', 'Sales', 'Technology', 'Business'];

  const hasDescription = (job) => {
    const description = job.description;

    if (typeof description === 'string') {
      return description.trim() !== '';
    }

    if (typeof description === 'object') {
      return (
        description.intro?.trim() ||
        description.role?.length > 0 ||
        description.experience?.length > 0 ||
        description.qualifications?.length > 0 ||
        description.location?.trim()
      );
    }

    return false;
  };

  const filteredJobs =
    selectedCategory === 'All'
      ? jobs.filter((job) => hasDescription(job))
      : jobs.filter(
          (job) => job.category === selectedCategory && hasDescription(job)
        );

  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Work with Us</Title>
      <Cards
        items={filteredJobs}
        linkPrefix='/careers/job'
        imageKey='image'
        titleKey='title'
        emptyMessage='No Open roles here'
        categories={categories}
        category={selectedCategory}
        setCategory={setSelectedCategory}
      />
    </div>
  );
};

export default CareersSection;