import { FaLaptopCode, FaCogs, FaRocket } from 'react-icons/fa';

const steps = [
  {
    id: 'develop',
    label: 'You Design',
    icon: <FaLaptopCode size={60} />,
    content:
      'Our financial engineers and specialised system architects assist you with financial product analysis and IT set-up requirements. A feasibility study helps you decide.',
  },
  {
    id: 'build',
    label: 'We Build',
    icon: <FaCogs size={60} />,
    content:
      'We develop the necessary modules for your products and establish connections with your chosen counterparties. Rigorous testing ensures reliability.',
    },
  {
    id: 'launch',
    label: 'You Launch',
    icon: <FaRocket size={60} />,
    content:
      'Your products run in Euroclear’s core data centres, supported by the Quantessence  team. Our loosely coupled architecture allows easy upgrades, innovations, and changes.', 
     },
];

export default steps;
