import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Title from '../Title';
import products from '../../data/products.js';
import '../../index.css';

const Role = () => {
  const { slug } = useParams();
  const product = products.find((product) => product.slug === slug);

  const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);
  const [flippedCards, setFlippedCards] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const roleContentRef = useRef(null);
  const roleSectionRef = useRef(null); 

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!product || !product.roles || product.roles.length === 0) {
    return <div>No roles available.</div>;
  }

  const handleRoleChange = (index) => {
    if (isMobile) {
      setSelectedRoleIndex(selectedRoleIndex === index ? null : index);
      roleSectionRef.current.scrollIntoView({ behavior: 'smooth' }); 
    } else {
      setSelectedRoleIndex(index);
    }
    setFlippedCards({});
  };

  const handleFlip = (subIndex) => {
    setFlippedCards((prev) => ({
      ...prev,
      [`${selectedRoleIndex}-${subIndex}`]: !prev[`${selectedRoleIndex}-${subIndex}`],
    }));
  };

  const selectedRole =
    selectedRoleIndex !== null ? product.roles[selectedRoleIndex] : null;

  return (
    <div className='flex flex-col items-center p-6 lg:p-12 gap-6 lg:gap-12 text-primary-blue'>
      <Title>How Quantessence Helps</Title>
      <div className='hidden md:flex flex-col justify-center items-center gap-12 max-w-6xl mx-auto w-full'>
        <div className='flex flex-row justify-center items-center md:p-6 lg:p-0 w-full gap-6'>
          {product.roles.map((role, index) => (
            <button
              key={index}
              onClick={() => handleRoleChange(index)}
              className={`flex flex-col items-center justify-center px-6 py-3 text-xl md:text-lg lg:text-2xl font-medium cursor-pointer ${
                selectedRoleIndex === index
                  ? 'text-primary-blue border-b-4 border-primary-red'
                  : 'text-primary-blue border-b-4 border-gray-300'
              }`}
            >
              {role.title}
            </button>
          ))}
        </div>
        {selectedRole && (
          <div ref={roleContentRef}>
            <div className='flex items-center justify-center text-center h-20'>
              <p className='text-lg lg:text-xl font-light max-w-6xl px-12 text-primary-blue '>
                {selectedRole.description}
              </p>
            </div>
            <div
              className={`flex flex-col ${
                selectedRole.subs && selectedRole.subs.length > 1
                  ? 'md:flex-row'
                  : 'md:flex-col'
              } items-center justify-center gap-6 p-12 w-full`}
            >
              {selectedRole.subs && selectedRole.subs.length > 0 ? (
                selectedRole.subs.map((sub, subIndex) => {
                  const cardWidth =
                    selectedRole.subs.length === 1
                      ? 'w-full md:w-2/3'
                      : selectedRole.subs.length === 2
                      ? 'w-full lg:w-1/2'
                      : 'w-full lg:w-80';
                  return (
                    <div
                      key={`${selectedRoleIndex}-${subIndex}`}
                      className={`flip-card ${cardWidth} h-80 cursor-pointer ${
                        flippedCards[`${selectedRoleIndex}-${subIndex}`]
                          ? 'flipped'
                          : ''
                      }`}
                      onClick={() => handleFlip(subIndex)}
                    >
                      <div className='flip-card-inner w-full h-full relative'>
                        <div
                          className='flip-card-front absolute flex items-center justify-center rounded w-full h-full  bg-cover bg-center text-white'
                          style={{ backgroundImage: `url(${sub.image})` }}
                        >
                          <div className='absolute inset-0 bg-black bg-opacity-40 rounded'></div>
                          <h2 className='relative z-10 p-6 text-center text-xl font-medium break-words'>
                            {sub.title}
                          </h2>
                        </div>

                        <div className='flip-card-back absolute flex items-center p-6 w-full h-full rounded text-white bg-primary-blue'>
                          <p className='text-justify'>{sub.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='text-lg text-primary-blue'>
                  No subs available for this role.
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-col md:hidden justify-center items-center w-full gap-6' ref={roleSectionRef}>
        {product.roles.map((role, index) => (
          <div key={index} className='w-full'>
            <button
              onClick={() => handleRoleChange(index)}
              className={`px-6 py-3 w-full text-center text-xl font-medium cursor-pointer ${
                selectedRoleIndex === index
                  ? 'text-primary-blue border-b-4 border-primary-red'
                  : 'text-primary-blue border-b-4 border-gray-300'
              }`}
            >
              {role.title}
            </button>
            {selectedRoleIndex === index && (
              <div ref={roleContentRef}>
                <div className='flex items-center justify-center mt-4 text-center'>
                  <p className='max-w-2xl text-lg font-light text-primary-blue'>
                    {role.description}
                  </p>
                </div>
                <div className='flex flex-col items-center justify-center mt-4 gap-4 w-full'>
                  {role.subs && role.subs.length > 0 ? (
                    role.subs.map((sub, subIndex) => (
                      <div
                        key={`${selectedRoleIndex}-${subIndex}`}
                        className={`flip-card w-full h-80 cursor-pointer ${
                          flippedCards[`${selectedRoleIndex}-${subIndex}`]
                            ? 'flipped'
                            : ''
                        }`}
                        onClick={() => handleFlip(subIndex)}
                      >
                        <div className='flip-card-inner relative w-full h-full'>
                          <div
                            className='flip-card-front absolute flex items-center justify-center rounded w-full h-full bg-cover bg-center text-white'
                            style={{ backgroundImage: `url(${sub.image})` }}
                          >
                            <div className='absolute inset-0 bg-black bg-opacity-40 rounded'></div>
                            <h2 className='relative z-10 text-xl font-medium break-words text-center'>
                              {sub.title}
                            </h2>
                          </div>

                          <div className='flip-card-back absolute flex items-center p-6 w-full h-full rounded text-white bg-primary-blue'>
                            <p className='text-justify'>{sub.description}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-lg text-primary-blue'>
                      No subs available for this role.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Role;