import React from 'react';
import { useParams } from 'react-router-dom';
import Title from '../Title.jsx';
import products from '../../data/products.js';

const ProductDescription = () => {
  const { slug } = useParams();
  const product = products.find((product) => product.slug === slug);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className='flex flex-col items-center p-6 md:p-12 gap-6 md:gap-12'>
      <Title>{product.title}</Title>
      <div className='flex flex-col lg:flex-row gap-6 lg:gap-12 max-w-6xl'>
          <div className='text-center text-lg lg:text-xl font-light leading-8 text-primary-blue'>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
      </div>
    </div>
  );
};

export default ProductDescription;