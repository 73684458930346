import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const navItems = [
  { to: '/', label: 'Home' },
  { to: '/services', label: 'Services' },
  { to: '/illustrations', label: 'Illustrations' },
  { to: '/news', label: 'News' },
  { to: '/about', label: 'About us' },
  { to: '/careers', label: 'Careers' },
];

const NavItems = ({ onItemClick }) => {
  const { pathname } = useLocation();

  return (
    <ul className='flex flex-col lg:flex-row gap-6'>
      {navItems.map(({ to, label }) => (
        <li key={to}>
          <Link
            to={to}
            className={`block text-lg font-medium ${
              (pathname === '/' && to === '/') ||
              (to !== '/' && pathname.startsWith(to))
                ? 'text-primary-red'
                : ''
            }`}
            onClick={() => {
              if (onItemClick) {
                onItemClick();
              }
            }}
          >
            {label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavItems;