import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={`py-2 px-3 text-white text-lg font-medium rounded bg-primary-blue cursor-pointer ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Button;