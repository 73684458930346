import React from 'react';
import { useParams } from 'react-router-dom';
import Title from '../Title.jsx';
import Card from '../Card.jsx';
import products from '../../data/products.js';

const ProductFeatures = () => {
  const { slug } = useParams();
  const product = products.find((product) => product.slug === slug);

  const featureCount = product.features.length;
  let gridClasses = 'grid grid-cols-1 gap-6';

  if (!product.features || product.features.length === 0) {
    return null;
  }  

  if (featureCount === 3) {
    gridClasses = `
      grid grid-cols-1 
      md:grid-cols-2 
      lg:grid-cols-3
      gap-6
      @media (min-width: 1100px) { 
        grid-cols-2;
      }
    `;
  } else if (featureCount === 4) {
    gridClasses = 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6';
  }

  return (
    <section className='flex flex-col items-center p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Key Features</Title>
      <div className='flex flex-col lg:flex-row gap-6 lg:gap-12 max-w-6xl'>
        <p className='text-center text-lg lg:text-xl font-light leading-8 text-primary-blue'>
          Discover the amazing features that make our product stand out. Each
          feature is designed to provide you with a seamless and enriching
          experience.
        </p>
      </div>
      <div className='flex flex-col lg:flex-row max-w-[1600px] mx-auto w-full'>
        <div className={gridClasses}>
          {product.features.map((feature, index) => (
            <div
              key={index}
              className={`
                ${index === 2 && featureCount === 3 ? 'md:col-span-2 lg:col-span-1' : ''} 
                h-full
              `}
            >
              <Card
                title={feature.title}
                description={feature.description}
                height='h-full'
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProductFeatures;