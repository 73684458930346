const employeenotice = {
  sections: [
    {
      heading: '1. Purpose of this notice?',
      paragraphs: [
        `In connection with your employment at Quantessence Financial ('Quantessence'), we collect and use personal data about you (whether provided directly by you or from another source).`,
        `We have written this notice to let you know:`,
        `• What personal data is collected;`,
        `• Why and how we collect that personal data;`,
        `• How we use it;`,
        `• Who we share it with and why; and`,
        `• To provide an explanation of your rights as a data subject in relation to that personal data.`,
        `This notice also includes the information that we are required to provide to you by the General Data Protection Regulation (GDPR).`,
        `Quantessence is the “data controller” of the personal data that is processed about you in the context of your employment.`,
        `It is important that the personal data we hold about you is accurate. Please keep us informed about any change to your personal data during your working relationship with Quantessence.`,
      ],
    },
    {
      heading: '2. What personal data do we collect and from whom?',
      paragraphs: [
        `We refer to appendix 1 for a separate comprehensive overview of what personal data we collect, process and the types of processing.`,
        `We collect your personal information from various sources, but mainly directly from you.`,
        `The personal data we collect in relation to you comes in most circumstances from you, but sometimes also from third parties or sources.`,
        `1. We collect personal data <b>directly from you</b>, in cases as:`,
        `• You provide us with it at the time you were hired at Quantessence, and during your employment with us (such as your contact details, information on your family members, your passport or bank account details, etc.);`,
        `• You fill in a form on our website or you send us an e-mail; or`,
        `• You provide us with personal data in the context of your career development.`,
        `2. We may also obtain some information from <b>third parties or other sources,</b> such as:`,
        `• HR and People management;`,
        `• Other employees or contractors;`,
        `• Third party companies and/or suppliers (this includes; suppliers that administer your employee benefits, insurance and social security organisations and tax authorities).`,
      ],
    },
    {
      heading: '3. Why do we collect your personal data?',
      paragraphs: [
        `We only collect and use your personal data for specific business purposes, in accordance with the legal grounds provisioned in the applicable privacy laws.`,
        `We only collect, use and process your personal data when one or more of the below legal bases apply:`,
        `1. <b>Compliance with a legal obligation</b> as an employer, such as our duties under labour, social security and immigration laws, record-keeping and reporting obligations;`,
        `2. <b>Contractual performance:</b> it is necessary to enter into an employment contract or manage the employment contract you have entered into with Quantessence (for example: people management, administering compensation and benefits, evaluating performance and career development);`,
        `3. <b>Legitimate business interest:</b>  examples are: training and coaching our staff, performance management, maintaining security, management of the physical access to premises, management of access to our IT systems, collecting emergency contacts, defending our legal position in case of legal proceedings. Quantessence ensures that the application of this legal ground does not override your interests or your fundamental rights and freedom which requires protection of personal data.`,
        `4. <b>Consent:</b>  You have, in strictly defined circumstances, given your consent to the processing (for example: the use of a picture of you taken during an event). We ensure that the consent is given separately for each processing, freely given, informed and explicit.`,
      ],
    },
    {
      heading: '4. Who do we share your personal data with?',
      paragraphs: [
        `For the above-mentioned purposes, we may transfer your personal data in the following circumstances:`,
        `1. Transfers within the Quantessence group or to third parties`,
        `2. International personal transfers`,
      ],
      subSections: [
        {
          heading:
            '4.1. Transfers within the Quantessence group or to third parties',
          paragraphs: [
            `• We may transfer your personal data within the Quantessence and Euroclear group where necessary for purposes consistent with this notice. We are careful to only allow access to those employees who have a legitimate business need (for example: to administer human resources and employee compensation and benefits). We also share this information for other legitimate business purposes (such as, IT services/security, tax and accounting purposes and general business management).`,
            `• We may disclose your personal data to our third-party contractors and consultants who provide data processing services to us, or who otherwise process personal data for purposes that are described in this notice or notified to you when we collect your personal data. This may include career coaches and training providers, services for the administration of employee compensation and benefits, payroll support services, HR information management, consultation and analysis services, relocation, tax and travel management services, as well as IT companies or service providers of software programs concerning HR data and/or storage of electronic data.`,
            `• Quantessence may also provide your personal data to any competent legal or regulatory body, governmental agency, court or other third party such as, but not limited to, police authorities, financial supervisory authorities and tax agencies, where disclosure is necessary (i) as a matter of applicable law or regulation, or (ii) to exercise, establish or defend our legal rights.`,
            `• We may also disclose your personal data to our auditors, advisors, legal representatives and similar agents in connection with the advisory services they provide to us, for strictly limited and specific business purposes.`,
            `• We may also disclose your personal data to a potential buyer, seller or joint venture (and its agents and advisers) in connection with any proposed purchase, merger or acquisition, if we inform our contractual party that it must use it for the purposes disclosed in this notice.`,
          ],
        },
        {
          heading: '4.2. International personal data transfers',
          paragraphs: [
            `It is possible that in some circumstances we may transfer some of your personal data outside the European Economic Area (EEA). In such circumstances, we take appropriate safeguards to require that your personal data will remain processed and protected in accordance with this notice and applicable data protection laws. These include implementing data protection clauses with our third-party vendors, contractors and consultants as well as EU Standard Contractual Clauses.`,
            `For the particular transfer of data between Quantessence’s head quarter, located in the EEA (Brussels) and its branch in the UK, we refer to the adequacy decision taken by the European Commission of June, 28 2021. Based on the UK GDPR, EEA data can continue to flow freely from the EEA area into the UK.`,
          ],
        },
      ],
    },
    {
      heading: '5. What are your rights as a data subject?',
      paragraphs: [
        `As a data subject, you have a number of legal rights with regards to the personal data that we hold about you and you can exercise these rights by contacting us using the contact details mentioned in appendix 2.`,
      ],
      subSections: [
        {
          heading: '5.1. The right to be informed',
          paragraphs: [
            `The right to information allows you (data subject) to know what personal data we collect, why it is collected, who is collecting the data, how long it will be kept and how to file a complaint.`,
            `Quantessence provides this notice to keep you informed of what we do with your personal data and all above-mentioned points.`,
          ],
        },
        {
          heading: '5.2. The right to access',
          paragraphs: [
            `You have the right to submit a request to access your personal data and check if we are lawfully processing them.`,
            `You can also request a copy of it.`,
          ],
        },
        {
          heading: '5.3. The right to rectification',
          paragraphs: [
            `The right to rectification allows you to ask the organization to update any incomplete, inaccurate or out-of-date data we have on you.`,
            `If you think this is the case, please let us know and we will rectify it as appropriate.`,
          ],
        },
        {
          heading: '5.4. The right to be forgotten',
          paragraphs: [
            `You have the right to request that Quantessence erases your personal data when these are not needed for the purposes listed in Section 3.`,
            `In limited circumstances it may not be possible to fully exercise this right, for instance, in the public interest or in compliance with legal obligations.`,
          ],
        },
        {
          heading: '5.5. The right to restrict processing',
          paragraphs: [
            `In some circumstances, you have the right to ask Quantessence to restrict how we process your personal data, for example, in case the personal data is inaccurate.`,
            `This means we are not obliged to delete the data, but we have to refrain from processing them and are only permitted to store the data.`,
          ],
        },
        {
          heading: '5.6. The right to data portability',
          paragraphs: [
            `Data portability allows you to obtain your personal data, that you have provided us, in a structured, commonly used and machine-readable format.`,
            `You also have the right to request the transfer of your personal data to another party.`,
          ],
        },
        {
          heading: '5.7. The right to object',
          paragraphs: [
            `You have the right to object to specific types of processing of your personal data, such as when processing is based on our legitimate interests.`,
            `We will then stop the processing, except if the law authorises us to pursue this processing activity.`,
            `The exercise of your right depends on the purpose of the processing and the lawful base for processing.`,
          ],
        },
        {
          heading: '5.8. The right to withdraw consent',
          paragraphs: [
            `In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal data, you have the right to withdraw your consent for that specific processing at any time.`,
            `Your withdrawal of consent shall not affect the lawfulness of the processing of your data based on your consent before its withdrawal.`,
            `The exercise of the above-mentioned rights are subject to conditions which are set out in the GDPR and while they are wide-ranging, in limited circumstances it may not be possible to fully exercise them, for example, you request personal data to be deleted but the law requires that we keep it.`,
            `If you would like to exercise one of the above rights, please send your request to the relevant Quantessence Privace Officer (by email or by post) using the contact details in appendix 2.`,
            `To facilitate the processing of your request, please provide the following information:`,
            `• An explanation of what you are requesting`,
            `• Your full name`,
            `• Your address (if corresponding by post)`,
            `• A copy of a valid identification document, e.g. passport, ID card, driving licence or extract of population register or register of foreign residents (where applicable).`,
          ],
        },
      ],
    },
    {
      heading: '6. How long is personal data kept?',
      paragraphs: [
        `Quantessence is committed to retaining your personal data in line with applicable legal requirements where we operate. We store your personal data as long as it is necessary for the purposes for which it was collected, as explained in this notice.`,
        `Further information can be found in the current retention policy which is updated as appropriate according to changes in legislation and good practice.`,
      ],
    },
    {
      heading: '7. Where and how do I file a complaint?',
      paragraphs: [
        `You have the right to complain to your local data protection authority about our collection and use of your personal data if you feel that Quantessence has not sufficiently addressed any concern or complaint. For more information, please contact your local data protection authority.`,
        `You can find contact details for many data protection authorities in Europe and beyond at the following link: http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.`,
        `Within the EEA, your rights arise from the General Data Protection Regulation. The relevant supervisory authority is the Data Protection Authority of Belgium (www.dataprotectionauthority.be).`,
        `In the UK, your rights arise from the UK General Data Protection Regulation, and the supervisory authority is the UK Information Commissioner (https://ico.org.uk).`,
      ],
    },
    {
      heading: '8. What about data security and quality?',
      paragraphs: [
        `Quantessence uses appropriate technical and organisational measures to protect the personal data that we collect and process about you, such as:`,
        `• Ensuring that we store your data in a secure operating environment, only accessible to Quantessence employees, contractors and consultants on a need-to-know basis. Quantessence also follows generally accepted industry standards to do this;`,
        `• Requiring third parties we disclose personal data to, to take appropriate security measures to protect it; and`,
        `• Rigorous verification of an individual’s identity prior to gaining access to any IT or business premises (e.g. through login ID, password, pin codes and badges).`,
      ],
    },
    {
      heading: '9. How often does Quantessence update this notice?',
      paragraphs: [
        `We may update this notice from time to time in response to changing legal, technical or business developments. When we make any significant material changes to this notice, we will inform you.`,
        `You can see when this notice was last updated by checking the “last updated” date displayed at the top of this notice. The most up-to-date version will be the one kept on the intranet.`,
      ],
    },
    {
      heading: '10. How can you reach us?',
      paragraphs: [
        `If you have any questions or concerns regarding our privacy practices, this notice, or our role under the GDPR, please address them to the Quantessence Privacy Officer using the details below:`,
      ],
      contacts: [
        {
          entity: 'Quantessence Financial NV/SA',
          officer: 'Privacy Officer EU GDPR',
          address: '21, Avenue du Boulevard, 5th floor, 1210 Brussels, Belgium',
          email: 'complianceteam@quantessence.com',
        },
        {
          entity: 'Quantessence Financial Ltd',
          officer: 'Privacy Officer UK GDPR',
          address: '33 Cannon Street, London, EC4M 5SB',
          email: 'complianceteam@quantessence.com',
        },
      ],
    },

    {
      heading: 'Appendix 1: Collected data and processing',
      subSections: [
        {
          heading: 'Staff on-boarding / off-boarding',
          paragraphs: [
            '1. Personal details',
            '2. Professional details',
            '3. Education / former experience and career-related data / CV',
            '4. Family situation',
            '5. Referral',
            '6. Offer letter / contract',
            '7. Information linked to the termination of the employment contract',
            '8. Where required by law, certificate of ability to work issued by a competent doctor',
            '9. Professional development / skills',
            '10. Performance details',
            '11. Work permit or visa data',
          ],
        },
        {
          heading: 'Screening for regulatory roles (if relevant)',
          paragraphs: [
            '1. Education and training',
            '2. Personal details',
            '3. Previous professional experience of 5 years',
            '4. Identification of managers during previous 5 years (UK only)',
            '5. Nationality / identity check',
            '6. Where required and/or permitted by law, judicial data i.e. about criminal convictions and offences; (and debt proceedings, UK only)',
            '7. Where required and/or permitted by law, personal details found on public websites when performing an adverse media check.',
            '8. Financial details, where required/permitted by law (UK only)',
            '9. Work permit or visa data',
          ],
        },
        {
          heading: 'HR Management',
          paragraphs: [
            '1. Personal details',
            '2. Professional details',
            '3. Past professional experience',
            '4. Signature of employee',
            '5. Compensation and benefits',
            '6. Education training and career-related data',
            '7. Family situation',
            '8. Performance details',
            '9. Presence time, working time, absence times and types and holidays',
            '10. Financial details',
            '11. Information to benefit from insurance cover',
            '12. Details regarding travel for professional purposes',
            '13. Where required and/or permitted by law, judicial data (i.e. about criminal convictions and offences)',
            '14. Where required by law, certificate of ability to work issued by a doctor',
            '15. Professional development/skills',
            '16. Work permit or visa data',
          ],
          items: [
            '1. Management of payroll and benefits for employees',
            '2. Expenses repayment',
            '3. Processing and management of pension funds',
            '4. Time and attendance',
            '5. Management of authorised signatories',
            '6. Insurance and claim management',
            '7. Employee performance measurement',
          ],
        },
        {
          heading: 'Reporting / Legal actions',
          paragraphs: [
            '1. Personal details',
            '2. Professional details',
            '3. Presence time on job and holidays',
            '4. Information pertaining to claim',
            '5. Information on public websites (e.g. social media)',
            '6. Financial details',
            '7. Training data',
            '8. Work permit or visa data',
          ],

          items: [
            '1. Handling employment litigation (including legal advice)',
            '2. Internal audit',
            '3. Report to management',
            '4. Regulatory reporting',
            '5. Fraud prevention',
          ],
        },
        {
          heading: 'Performance review and employee development',
          paragraphs: [
            '1. Personal details',
            '2. Professional details',
            '3. Compensation details',
            '4. Presence time on job / holidays',
            '5. Performance details and evaluation',
            '6. Education, training and career-related data',
            '7. Results from personality and behavioural tests',
            '8. Professional development/skills information',
          ],
          items: [
            '1. Collection of input, drafting of reports and advice for compensation review and bonus',
            '2. Setting future development plan and career coaching',
            '3. Provide necessary training',
          ],
        },
        {
          heading: 'Well-being at work',
          paragraphs: [
            '1. Personal details',
            '2. Professional data',
            '3. (Duration of) incapacity, sickness (when/as permitted by law)',
            '4. Disciplinary records, investigation records, or reports related to conduct impacting the workplace',
            '5. Ethnicity (where permitted by law)',
          ],
          items: [
            '1. Medical check-ups / controls / first aid',
            '2. Manage adaptation requests (e.g. specific ergonomic furniture, time-off etc.)',
            '3. Harassment, conflict of interests',
            '4. Work accidents',
            '5. Diversity monitoring (where permitted by law)',
          ],
        },
        {
          heading: 'Security and business continuity',
          paragraphs: [
            '1. Personal details',
            '2. Professional details',
            '3. Overview of office access',
          ],
          items: [
            '1. Business continuity (contact employee in case of urgency)',
            '2. Risk management',
          ],
        },
        {
          heading: 'IT & Security',
          paragraphs: [
            '1. Personal details',
            '2. Professional details',
            '3. Calendar information',
            '4. Log in data',
            '5. Content of professional electronic messages',
          ],
          items: [
            '1. Access to Windows and peripheral systems',
            '2. Security analysis/monitoring',
            '3. Web access (corporate network, wifi and proxy)',
            '4. Identify and prevent loss of data and ensuring confidentiality, integrity and availability of data',
            '5. Avoid malware / viruses',
            '6. Software management',
            '7. IT projects',
          ],
        },
        {
          items: [
            'Voice recording – when required/permitted by law e.g. for recording financial transactions (MiFiD) or contact centre training and quality',
          ],
          paragraphs: [
            '1. Caller ID',
            '2. Time and date of call',
            '3. Call recording',
          ],
        },
        {
          items: ['Provide mobile device management (corporate device, BYOD)'],
          paragraphs: [
            '1. Business card details',
            '2. Device type and model, Operating system',
            '3. Private or corporate device',
            '4. Operator / Carrier',
            '5. IMEI',
            '6. Serial number (of device)',
            '7. Wi-Fi MAC Address',
            '8. Device ID',
            '9. Apps installed on the device',
          ],
        },
        {
          items: [
            'Contact with regulators and public authorities (complying with regulatory requirements, investigations, disclosure requests and / or other requests from/to regulators and public authorities)',
          ],
          paragraphs: [
            '1. Business card details',
            '2. Professional / Educational background',
            '3. Judicial data',
          ],
        },
      ],
    },
  ],
};

export default employeenotice;
