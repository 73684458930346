import React from 'react';

const ProductVideo = ({ videoUrl }) => {
  const defaultVideoUrl = 'https://www.youtube.com/embed/REsYnLm6FAY';
  const videoSrc = videoUrl || defaultVideoUrl;

  return (
    <div className='flex flex-col items-center p-6 md:p-12 gap-6 md:gap-12'>
      <div className='flex w-full max-w-4xl'>
        <iframe
          src={videoSrc}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          className='w-full aspect-video rounded shadow-lg'
        ></iframe>
      </div>
    </div>
  );
}

export default ProductVideo;