import React from 'react';
import Title from '../Title';
import Cards from '../Cards';
import products from '../../data/products.js';

const IllustrationSection = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Technology-Backed Innovation</Title>
      <div className='text-center'>
        <p className='text-lg lg:text-xl font-light text-gray-500'>
          {`Client Success Stories Supported by `}
          <span className='font-medium text-primary-red'>Quantessence</span>
        </p>
      </div>
      <Cards
        items={products}
        linkPrefix='/illustrations/product'
        imageKey='imagedisplay'
        titleKey='title'
        linkKey='slug'
        setCategory={() => {}}
        applyObjectTop={true}
      />
    </div>
  );
};

export default IllustrationSection;
