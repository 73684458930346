import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Services from './pages/Services';
import Illustrations from './pages/Illustrations';
import Company from './pages/Company';
import Careers from './pages/Careers';
import Job from './subpages/Job';
import Article from './subpages/Article';
import News from './pages/News';
import Contact from './pages/Contact';
import Homepage from './pages/Homepage';
import Footer from './components/Footer';
import Product from './subpages/Product';
import Employee from './pages/Employee';
import Privacy from './pages/Privacy';
import Legal from './pages/Legal';
import SiteMap from './pages/SiteMap';
import Cookie from './pages/Cookie';

import './App.css';


function App() {
  return (
    <Router>
      <Header />
      <div className='app-content'>
        <Routes>
          {/* Main pages */}
          <Route path='/' element={<Homepage />} />
          <Route path='/services' element={<Services />} />
          <Route path='/illustrations' element={<Illustrations />} />
          <Route path='/about' element={<Company />} />
          <Route path='/news' element={<News />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/contact' element={<Contact />} />
          {/* Sub pages */}
          <Route path='/illustrations/product/:slug' element={<Product />} />
          <Route path='/careers/job/:slug' element={<Job />} />
          <Route path='/news/article/:slug' element={<Article />} />
          {/* Footer pages*/}
          <Route path='/employeepolicy' element={<Employee />} />
          <Route path='/cookielist' element={<Cookie />} />
          <Route path='/privacypolicy' element={<Privacy />} />
          <Route path='/legalnotice' element={<Legal />} />
          <Route path='/sitemap' element={<SiteMap />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;