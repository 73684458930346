import React from 'react';
import Title from '../Title.jsx';
import { Link } from 'react-router-dom';

const siteMapItems = [
  { to: '/', label: 'Home' },
  { to: '/services', label: 'Services' },
  { to: '/illustrations', label: 'Illustrations' },
  { to: '/news', label: 'News' },
  { to: '/about', label: 'About us' },
  { to: '/careers', label: 'Careers' },
  { to: '/employeepolicy', label: 'Employee Policy' },
  { to: '/privacypolicy', label: 'Privacy Policy' },
  { to: '/legalnotice', label: 'Legal Notice' },
];

const SiteMap = ({ onItemClick }) => {
  return (
    <>
      <div className='flex flex-col items-center pt-6'>
        <Title>Site Map</Title>
      </div>
      <div className='flex flex-col px-12 py-6 text-primary-blue'>
        <ul className='flex flex-col gap-4'>
          {siteMapItems.map(({ to, label }) => (
            <li key={to}>
              <Link
                to={to}
                className='text-lg font-medium'
                onClick={() => {
                  if (onItemClick) {
                    onItemClick();
                  }
                }}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SiteMap;