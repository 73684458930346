import React from 'react';

const Title = (props) => {
  return (
    <>
      <div className='flex flex-col justify-center items-center w-full'>
        <h2 className='text-2xl md:text-3xl font-medium text-primary-blue text-center'>
          {props.children}
        </h2>
      </div>
    </>
  );
}

export default Title;