import React from 'react';
import locations from '../../data/locations';
import Title from '../Title';
import LocationsCards from './LocationsCards';

const Locations = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Our Locations</Title>
      <div className='flex flex-col lg:flex-row items-center justify-center gap-12'>
        {locations.map((location, index) => (
          <LocationsCards key={index} location={location} />
        ))}
      </div>
    </div>
  );
};

export default Locations;