import {
  FaChartLine,
  FaCog,
  FaShieldAlt,
  FaTachometerAlt,
} from 'react-icons/fa';

const values = [
  {
    icon: FaChartLine,
    title: 'Sustainable Revenues',
    description:
      'Individualisation, innovation and flexibility enhance customer retention, leading to durable, long-term revenues.',
  },
  {
    icon: FaCog,
    title: 'Cost Optimisation',
    description:
      'We eliminate IT barriers, enabling efficient and cost-effective business operations.',
  },
  {
    icon: FaShieldAlt,
    title: 'Risk Reduction',
    description:
      'Built on Euroclear’s core infrastructure, our platform ensures long-term reliability and resilience.',
  },
  {
    icon: FaTachometerAlt,
    title: 'Fast Time-to-Market',
    description:
      'Fast implementation by experienced teams. Your products are ready in the short term and built for the long term.',
  },
];

export default values;
