import React from 'react';
import Title from '../../components/Title';
import ContactForm from '../Contact/ContactForm';
import ContactMap from '../Contact/ContactMap';

const ContactSection = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Contact us</Title>
      <div className='flex flex-col lg:flex-row gap-12 mx-auto w-full max-w-[1600px]'>
        <ContactForm />
        <ContactMap />
      </div>
    </div>
  );
}

export default ContactSection;