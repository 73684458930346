import React from 'react';
import Title from '../Title';
import Cards from '../Cards';
import articles from '../../data/articles';

const NewsSection = () => {
  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Press Release</Title>
      <div className='text-center'>
        <p className='text-lg lg:text-xl font-light text-gray-500'>
          {`Connect with us on `}
          <a
            href='https://www.linkedin.com/company/quantessence-financial-ltd/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='font-medium text-[#0277b5]'>Linkedin</span>
          </a>
        </p>
      </div>
      <Cards
        items={articles}
        linkPrefix='/news/article'
        imageKey='imgSrc'
        titleKey='title'
        linkKey='slug'
        emptyMessage='No articles available'
        showDetails={true}
        details={{ date: 'date' }}
        buttonLabel='Read More'
      />
    </div>
  );
};

export default NewsSection;