import React from 'react';
import ServiceSection from '../components/Services/ServiceSection';
import ValuesSection from '../components/Services/ValuesSection';
import StepperSection from '../components/Services/StepperSection';

function Services() {
  return (
    <>
      <ServiceSection />
      <ValuesSection />
      <StepperSection />
    </>
  );
}

export default Services;