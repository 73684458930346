import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Modal from './Modal';
import team from '../../data/team';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';

const TeamSlider = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [showDots, setShowDots] = useState(window.innerWidth < 640);
  const [showArrows, setShowArrows] = useState(window.innerWidth >= 640);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(1);
        setShowDots(true);
        setShowArrows(false);
      } else if (window.innerWidth < 992) {
        setSlidesToShow(2);
        setShowDots(true);
        setShowArrows(false);
      } else {
        setSlidesToShow(3);
        setShowDots(false);
        setShowArrows(true);
      }
    };

    updateSlidesToShow();
    window.addEventListener('resize', updateSlidesToShow);
    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []);

  const settings = {
    dots: showDots,
    infinite: true,
    speed: 1000,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: showArrows,
    centerMode: false,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const handleCardClick = (slide) => {
    setSelectedCard(slide);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };

  return (
    <div className='max-w-7xl mx-auto w-full'>
      <Slider {...settings} ref={sliderRef} className='pb-12'>
        {team.map((slide) => (
          <div key={slide.id} className='px-2'>
            <div
              className='flex flex-col items-center justify-center p-6 gap-6 h-96 bg-white shadow-lg rounded overflow-hidden text-primary-blue text-center cursor-pointer'
              onClick={() => handleCardClick(slide)}
            >
              <img
                src={slide.image}
                alt={slide.name}
                className='w-36 h-36 border rounded-full object-cover object-top'
              />
              <h2 className='text-xl lg:text-2xl font-medium'>{slide.name}</h2>
              <p className='text-lg lg:text-xl font-light'>{slide.title}</p>
              <a
                href={slide.linkedin}
                target='_blank'
                rel='noopener noreferrer'
                className='text-2xl cursor-pointer'
              >
                {slide.icon}
              </a>
            </div>
          </div>
        ))}
      </Slider>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        selectedCard={selectedCard}
      />
    </div>
  );
}

export default TeamSlider;