import React from 'react';
import PrivacyPolicy from '../components/Policies/PrivacyPolicy';

function Privacy() {
  return (
    <>
      <PrivacyPolicy />
    </>
  );
}

export default Privacy;