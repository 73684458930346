import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import '../index.css';

const Cards = ({
  items,
  linkPrefix,
  imageKey,
  titleKey,
  emptyMessage,
  categories,
  category,
  setCategory,
  showDetails = false,
  details = {},
  buttonLabel,
  applyObjectTop = false,
}) => {
  return (
    <>
      {categories && (
        <div className='mx-auto max-w-[1600px] w-full'>
          <div className='flex flex-wrap justify-start gap-4'>
            {categories.map((cat) => (
              <button
                key={cat}
                onClick={() => setCategory(cat)}
                className={`py-3 px-6 rounded text-lg cursor-pointer border-2 
              ${
                category === cat
                  ? 'text-white bg-primary-red border-primary-red'
                  : 'text-primary-red border-primary-red'
              }`}
              >
                {cat}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className='mx-auto max-w-[1600px] w-full'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12'>
          {items.length > 0 ? (
            items.map((item, index) => (
              <Link key={index} to={`${linkPrefix}/${item.slug}`}>
                <div className='h-full bg-white shadow-lg rounded overflow-hidden flex flex-col'>
                  <img
                    src={item[imageKey]}
                    alt={item[titleKey]}
                    className={`w-full h-52 object-cover ${
                      applyObjectTop && index === 4 ? 'xl:object-top' : ''
                    }`}
                  />
                  <div className='p-6 flex-grow flex flex-col'>
                    <h2 className='overflow-hidden text-xl lg:text-2xl text-primary-blue text-ellipsis'>
                      {item[titleKey]}
                    </h2>
                    {showDetails && details.date && (
                      <p className='text-gray-500 text-sm mb-2'>
                        {item[details.date]}
                      </p>
                    )}
                    {showDetails && details.description && (
                      <p className='flex-grow overflow-hidden text-lg lg:text-xl'>
                        {item[details.description]}
                      </p>
                    )}
                  </div>
                  {buttonLabel && (
                    <div className='p-6 mt-auto'>
                      <Button>{buttonLabel}</Button>
                    </div>
                  )}
                </div>
              </Link>
            ))
          ) : (
            <p className='text-lg text-gray-600'>{emptyMessage}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Cards;
