import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {createUrl} from '../../utils.js'

  const endpoint = createUrl('/RECAPTCHA-SITE-KEY');
  console.log("GET TO " + endpoint);
  const response = await fetch(endpoint);
  const sitekey = await response.text();  
	
const ReCaptcha = forwardRef(({ onVerify }, ref) => {
  const recaptchaContainerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    resetRecaptcha: () => {
      if (window.grecaptcha && recaptchaContainerRef.current) {
        window.grecaptcha.reset(); 
      }
    },
  }));
  
  useEffect(() => {	
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        if (recaptchaContainerRef.current && !recaptchaContainerRef.current.childNodes.length) {
          window.grecaptcha.render(recaptchaContainerRef.current, {
            sitekey: sitekey, 
            callback: onVerify,  
          });
        }
      });
    }
  }, [onVerify]);

  return <div className="mt-8" ref={recaptchaContainerRef}></div>;
});

export default ReCaptcha;
