const legaldata = {
    sections: [
      {
        heading: '1. Conventions',
        paragraphs: [
          `Capitalised terms used in this disclaimer shall have the following meaning:`,
          `•  “Quantessence” or “We” means the relevant entities of the Quantessence group;`,
          `•  “Website” means quantessence.com and all its pages;`,
          `•  “Content” means all content, data and/or information on the Website made available to You via the Website;`,
          `•  “You” means a client/participant of Quantessence and/or any user of this Website;`,
          `•  All times indicated on the Quantessence Website are Central European (Summer) Time, unless we say otherwise;`,
          `•  The term “country”, used interchangeably with “economy”, does not imply political independence but refers to any territory for which authorities report separate social or economic statistics.`,
        ],
      },
      {
        heading: '2. About the information and data You find on Quantessence.com',
        paragraphs: [
          `The content made available on this Website:`,
          `•  Is for information purposes only;`,
          `•  Does not replace any Content We send You in connection with the use of Quantessence services;`,
          `•  Is not an offer to enter into a contract with You;`,
          `•  Does not replace or form part of a contract we may have with You unless we say so in our contract (if you are a client of Quantessence, please see Posting and email notification of contractual documents below);`,
          `•  If any Content on the Website is inconsistent with any Quantessence standard form or any contract You’ve entered into with Quantessence, the relevant form or contract prevails except if we have expressly set out different rules in such form or contract.`,
        ],
      },
      {
        heading: '3. About links You find to other sites',
        paragraphs: [
          `We may provide links to sites operated or offered by third parties. We provide such links solely as a convenience to You.`,
          `Quantessence:`,
          `•  Has no responsibility or liability for any content posted on any sites linked to our Website;`,
          `•  Does not endorse or recommend any third party or any of the products and/or services offered on those sites;`,
          `•  Shall have no liability to You, or any responsibility whatsoever, for the failure of any connection or communication service to provide or maintain Your access to this Website or for any interruption or disruption of such access or any erroneous communication between Us and You, regardless of whether the connection or communication service is provided by Us or a third party service provider;`,
          `•  Shall have no liability to You for the correctness, quality, accuracy, timeliness, reliability, performance, continued availability, completeness or for any delays, omissions, or interruptions in the delivery of the Content on this Website, and`,
          `•  If access to this Website is unavailable, delayed or limited, or if this Website does not operate quickly and efficiently You may, for example, be unable to retrieve information on a timely basis or if your operations are dependent on such communications with Quantessence and such communications are disrupted or delayed, You may suffer losses. Quantessence will not be liable for any such loss as stated above.`,
        ],
      },
      {
        heading: '4. About Your use of our Website, Content and rights of access',
        paragraphs: [
          `You are permitted to store, display, analyse, modify, reformat, and print the Content made available to You from the Website only for Your own non-commercial use.`,
          `You are responsible for complying with the laws of the jurisdiction from which You are accessing this Website. You agree that You will not access or use the Content or the Website in violation of such laws. Unless expressly stated otherwise herein, any information submitted by You through this Website shall be deemed non-confidential and non-proprietary. You represent that You have the lawful right to submit such information and agree that You will not submit any information unless You are legally entitled to do so.`,
          `You are not permitted to:`,
          `•  Misuse Content posted on this Website;`,
          `•  Publish, transmit, or otherwise reproduce the Website or any of its Content, in whole or in part, in any format or to provide any part of the Website or any of its Content to any third party unless we have given You our prior written consent or allowed You to do so in a contract;`,
          `•  Alter, obscure or remove any copyright, trademark or any other proprietary notices or markings that are shown or provided to You on the Content or via the Website; or`,
          `•  Do any automatic querying, scanning and/or copying of any of the Content unless we have expressly allowed You to do this in a contract.`,
        ],
      },
      {
        heading: '5. About Quantessence’s reserved rights',
        paragraphs: [
          `All Content contained within this Website is protected by intellectual property and/or other proprietary rights. You shall acquire no rights, title or interest in the Website or any of the Content made available through the Website unless we expressly grant this to You in a contract.`,
          `We reserve the right in our sole discretion to immediately block all automatic querying, scanning and/or copying of the Content available on our Website without prior notice to You.`,
          `Your use of this Website may be monitored by us and the resulting information may be used by us for internal business purposes.`,
          `We make no warranty whatsoever to You, express or implied, regarding the security of the Website, including with respect to the ability of unauthorized persons to intercept or access information transmitted by You through the Website or any service on the Website.`,
        ],
      },
      {
        heading: '6. About Quantessence’s warranties and representations',
        paragraphs: [
          `We exclude to the fullest extent permitted by law all conditions, guarantees, warranties and/or representations of any kind with regard to Your use of our Content and Website, including, but not limited to, any implied conditions or warranties, any non-infringement of third party rights, freedom from viruses or other harmful code or fitness for a particular purpose. In no event will Quantessence be liable to You for any direct, indirect, incidental, special, exemplary, consequential losses, and/or other damages (including, but not limited to, lost profits, business interruption, loss of programs or data) without regard to the form of action and whether in contract, tort, negligence, strict liability, or otherwise, arising out of or in connection with this Website or any other content accessed through this Website or any site service linked to, or any copying, displaying, or use thereof.`,
          `We do not have any responsibility to:`,
          `•  Maintain the Content made available on this Website;`,
          `•  Availability of the Contents are subject to change without notice;`,
          `•  You shall be responsible for obtaining and maintaining all telephone, networks, telecommunication lines and connections, computer hardware, software, and/or other equipment needed to access and use this Website;`,
          `•  Access to this Website may from time to time be unavailable, delayed, limited or slower due to, among other things:`,
          `–       Hardware or system failure, including among other things, failures of computers (including your own computer), servers, networks, telecommunication lines and connections, and other electronic and mechanical equipment;`,
          `–       Software failure, including among other things bugs, errors, viruses, configuration problems, incompatibility of systems, utilities or applications, the operation of firewalls or screening programs, unreadable codes, or irregularities within particular documents or other content;`,
          `–       Unforeseen causes or events beyond our reasonable control including but not limited to severe weather, earthquakes, wars, insurrection, riots, strikes or labour stoppages, terrorism, civil commotion, accident, fire, water damage, explosion, mechanical breakdown or natural disasters; or`,
          `–       Interruption (whether partial or total) of power supplies or other service utility;`,
          `–       Governmental or regulatory restrictions, exchange rulings, court or tribunal orders or other human intervention.`,
        ],
      },
      {
        heading: '7. Our liability for emails and other information-forwarding services',
        paragraphs: [
          `Quantessence accepts no liability whatsoever for the provision of Quantessence Information forwarded to you by e-mail or other circumstances related to this facility, including without limitation: (i) misuse of the password, (ii) interception or alteration of the Quantessence Information, (iii) revocation of the password, (iv) the timeliness, accuracy, completeness or correctness of the Quantessence Information provided to You, or (v) any failure by you to receive any form of report from Quantessence due to technical problems involving the e-mail transmission or due to a failure of whatever nature by third parties.`,
          `You acknowledge and agree that this information may be of confidential nature and you expressly authorise Quantessence to make this information available to you in the context of the facilities offered via e-mail.`,
          `Save where the contractual documentation provides to the contrary, Quantessence:`,
          `(i)             Does not commit to provide you with any form of report via e-mail, and`,
          `(ii)            Is entitled to discontinue the facilities via e-mail at any time as determined in its sole discretion with immediate effect without notice or otherwise, and`,
          `(iii)           Does not undertake to provide this information within a given time.`,
        ],
      },
      {
        heading: '8. E-mail Disclaimer',
        paragraphs: [
          `All email messages we send, including any attachments, are confidential. They may contain legally privileged information.`,
          `If You:`,
          `·       Are not the intended recipient, You should not read, copy, distribute, disclose or use this information in any other way.`,
          `·       Have received a message in error, we kindly ask You to let us know immediately. Please then delete it from Your system.`,
          `We:`,
          `·       Cannot guarantee the integrity and security of email messages as they may get corrupted or unauthorised parties may amend them. We accept no liability for this.`,
          `·       Reserve to retain email messages on our systems. Subject to applicable law, we may monitor and intercept email messages to and from our systems.`,
        ],
      },
      {
        heading: '9. Privacy and Cookie Policy',
        paragraphs: [
          `Quantessence Financial may collect information about you when you visit the website. Quantessence Financial also uses cookies to collect certain information.`,
          `Please read our
           <a
                href='/privacypolicy'
                target='_blank'
                rel='noreferrer'
              >
                <span class='text-primary-red'> Privacy and Cookie Policy</span>
              </a>
           for more information.`,
        ],
      },
      {
        heading: '10. Applicable law and jurisdiction',
        paragraphs: [
          `The entire content of this website, including the legal information, is governed by Belgian law and needs to be interpreted accordingly. The Belgian courts have exclusive jurisdiction on any litigations that may arise relating to this website or its use.`,
        ],
      },
      {
        heading: '11. Quantessence’s complaints policy and contact details',
        paragraphs: [
          `At Quantessence, we care about how we do business with our clients. If you are not satisfied with the services we offer, we are keen to address any issues and would urge you to discuss such issues with your regular contact(s) at Quantessence. However, if you are still not satisfied and would like to make a formal complaint please follow the procedure below:`,
          `Please write, email, or telephone using the contact details below.`,
          `· email address : <a href='mailto:ComplianceTeam@quantessence.com' class='text-blue-600'>QFSA Compliance Team</a>`,
          `· postal address : For the attention of the Compliance Team, Quantessence SA/NV, 20 Avenue du Boulevard, 5th floor, 1210 Brussels, Belgium.`,
          `Please include your name and address, description of the nature and the content of your complaint and any relevant documentation in your letter or email.`,
          `All formal complaints are referred immediately to the Compliance Team at Quantessence for investigation and on receipt of a complaint, Quantessence will send a written acknowledgement and ensure you are kept informed thereafter. We may request further information and/or seek clarification from you on your complaint. We will provide you with a written response within a reasonable period of time.`,
          `Please note that Quantessence will only use this information for the purpose of handling your complaint fairly, promptly and appropriately. We will treat all data collected in accordance with the relevant legal and contractual provisions on confidentiality and personal data.`,
        ],
      },
    ]
};

export default legaldata;