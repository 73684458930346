import React, { useState, useEffect } from 'react';
import Video from '../../assets/splash/Techsplash.mp4';
import DesignerImage from '../../assets/Designer.jpeg';
import MobileGif from '../../assets/splash/Safarigif.gif';

const Splash = () => {
  const [isSafari, setIsSafari] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const detectSafariAndMobile = () => {
      const ua = navigator.userAgent.toLowerCase();
      const safariCheck = /safari/.test(ua) && !/chrome/.test(ua) && !/android/.test(ua);
      const mobileCheck = /iphone|ipad|ipod|android|blackberry|windows phone|opera mini/.test(ua);
      setIsSafari(safariCheck);
      setIsMobile(mobileCheck);
    };

    detectSafariAndMobile();
  }, []);

  return (
    <div className='relative flex justify-center items-center w-full h-screen bg-black'>
      {isSafari || isMobile ? (
        // Render GIF for Safari or Mobile
        <div className='absolute w-full h-full'>
          <img
            src={MobileGif}
            alt='Splash GIF'
            className='w-full h-full object-cover'
            style={{
              WebkitTransform: 'translateZ(0)',
              transform: 'translateZ(0)',
            }}
          />
        </div>
      ) : (
        <>
          {/* Render Video for non-Safari and non-Mobile */}
          <div className='absolute w-full h-full'>
            <video
              src={Video}
              autoPlay
              muted
              loop
              className='w-full h-full object-cover'
              style={{
                WebkitTransform: 'translateZ(0)',
                transform: 'translateZ(0)',
              }}
            />
          </div>
          {/* Render Designer Image overlay only if not Safari or Mobile */}
          {!isMobile && !isSafari && (
            <div
              className='absolute w-full h-full'
              style={{
                backgroundImage: `url(${DesignerImage})`,
                backgroundSize: '100% auto',
                WebkitBackgroundSize: '100% auto',
                mixBlendMode: 'color',
                WebkitMixBlendMode: 'color',
                opacity: '0.7',
                WebkitOpacity: '0.7',
                transition: 'opacity 0.3s ease-in-out',
                WebkitTransition: 'opacity 0.3s ease-in-out',
              }}
            ></div>
          )}
        </>
      )}
      {/* Content */}
      <div className='relative z-30 text-white text-center'>
        <h1 className='flex flex-col gap-8 text-xl xs:text-2xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl'>
          <span className='inline-block w-full text-center capitalize font-medium antialiased'>
            Expert Technology Partner for
          </span>
          <span className='inline-block w-full text-center capitalize font-medium antialiased'>
            Bespoke Investment Solutions
          </span>
        </h1>
      </div>
      <div className='absolute bottom-0 m-4'>
        <p className='text-center text-xs text-white'>
          Quantessence is authorised and regulated by the Financial Services and
          Markets Authorities (FSMA) in Belgium and by the Financial Conduct
          Authority (FCA) in the UK (firm reference number 975752)
        </p>
      </div>
    </div>
  );
};

export default Splash;
