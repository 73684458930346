import React from 'react';
import CareersSection from '../components/Careers/CareersSection'; 

const Careers = () => {
  return (
    <>
      <CareersSection />
    </>
  );
};

export default Careers;
