import React from 'react';
import Title from '../Title.jsx';
import privacyCookie from '../../data/privacycookie.js';

const PrivacyPolicy = () => {
  const sections = privacyCookie?.sections || [];

  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12 text-center max-w-[1600px] mx-auto w-full'>
      <Title>Privacy and Cookie Policy</Title>
      <p className='text-lg lg:text-xl font-light text-gray-500'>
        {`Published by `}
        <span className='font-medium text-primary-red'>Quantessence</span>
        {` on 13th November 2024 `}
      </p>
      {sections.map((section, index) => {
        const isContactSection = section.heading?.includes('Contact Details');
        return (
          <div
            key={section.id || index}
            className='w-full text-primary-blue text-justify'
          >
            <p className='text-xl lg:text-2xl font-medium'>{section.heading}</p>
            {section.paragraphs?.map((paragraph, paraIndex) => (
              <p
                key={paraIndex}
                className='text-lg lg:text-xl font-light'
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
            {isContactSection && section.contacts?.length >= 2 && (
              <div>
                <table className='table-auto lg:hidden w-full mt-6 border text-lg lg:text-xl font-light'>
                  <tbody>
                    <tr>
                      <p className='border p-2 w-full text-left text-lg lg:text-xl font-medium text-white bg-primary-red'>
                        Quantessence Head Quarter – Belgium
                      </p>
                      <div className='p-2'>
                        {section.contacts[0].entity}
                        <br />
                        <strong>{section.contacts[0].officer}</strong>
                        <br />
                        {section.contacts[0].address}
                        <br />
                        <br />
                        email: {section.contacts[0].email}
                      </div>
                    </tr>
                    <tr>
                      <p className='border p-2 w-full text-left text-lg lg:text-xl font-medium text-white bg-primary-red'>
                        Quantessence Branch – UK
                      </p>
                      <div className='p-2'>
                        {section.contacts[1].entity}
                        <br />
                        <strong>{section.contacts[1].officer}</strong>
                        <br />
                        {section.contacts[1].address}
                        <br />
                        <br />
                        email: {section.contacts[1].email}
                      </div>
                    </tr>
                  </tbody>
                </table>
                <table className='table-auto hidden lg:table w-full mt-6 text-lg lg:text-xl font-light'>
                  <thead className='flex'>
                    <tr className='flex flex-col flex-1'>
                      <th className='border p-2 text-left text-lg lg:text-xl font-medium text-white bg-primary-red'>
                        Quantessence Head Quarter – Belgium
                      </th>
                      <td className='flex flex-col border p-2'>
                        {section.contacts[0].entity}
                        <br />
                        <p className='font-medium'>
                          {section.contacts[0].officer}
                        </p>
                        <br />
                        {section.contacts[0].address}
                        <br />
                        <br />
                        email: {section.contacts[0].email}
                      </td>
                    </tr>
                    <tr className='flex flex-col flex-1'>
                      <th className='border p-2 text-left text-lg lg:text-xl font-medium text-white bg-primary-red'>
                        Quantessence Branch – UK
                      </th>
                      <td className='flex flex-col border p-2'>
                        {section.contacts[1].entity}
                        <br />
                        <p className='font-medium'>
                          {section.contacts[1].officer}
                        </p>
                        <br />
                        {section.contacts[1].address}
                        <br />
                        <br />
                        email: {section.contacts[1].email}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            )}
            {!isContactSection &&
              section.subSections?.map((subSection, subIndex) => (
                <div key={subSection.id || subIndex}>
                  <p className='text-lg lg:text-xl font-medium'>
                    {subSection.heading}
                  </p>
                  <br />
                  {subSection.paragraphs?.map((para, paraIndex) => (
                    <p
                      key={paraIndex}
                      className='text-lg lg:text-xl font-light'
                      dangerouslySetInnerHTML={{ __html: para }}
                    />
                  ))}
                  {subSection.items && (
                    <ul>
                      {subSection.items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default PrivacyPolicy;