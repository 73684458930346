import Article1 from '../assets/articles/Phoenix.png';
import Article2 from '../assets/articles/Blackrock.png';
import Article3 from '../assets/articles/mass.webp';
import Article4 from '../assets/articles/fsma.jpg';
import Article5 from '../assets/articles/fund.png';
import Article6 from '../assets/articles/TSolutions.png';
import Article7 from '../assets/articles/munich.jpeg';
import Article8 from '../assets/articles/generali.jpeg';

const articles = [
  {
    slug: 'phoenix-collaboration',
    imgSrc: Article1,
    imgAlt: 'article1',
    date: '24th June 2024',
    title: 'Quantessence and Phoenix Group Announce Collaboration Success',
    description:
      'Quantessence and the Phoenix Group are pleased to announce a successful collaboration....',
    descriptionfull: `
    Quantessence and the Phoenix Group are pleased to announce a successful collaboration resulting in the launch of the Standard Life Smoothed Return Pension Fund, made available through Fidelity’s Adviser Solutions platform. Quantessence, a Euroclear company, provides technological support for the long-term savings market, primarily for innovative, granular, and individualised products.
    <br><br>
    Since 2023, Quantessence has been providing technological services to Phoenix Group, leading to this milestone achievement. The integration, completed in less than a year, required close cooperation between Phoenix Group, Fidelity International, and Quantessence.
    <br><br>
    The Euroclear Quantessence platform played a crucial role in this achievement, providing seamless connections between parties, automated calculations, and flawless processing. With its unique open architecture setup, the platform connects smoothed fund providers with distribution platforms, based on the underlying funds of their choice. Quantessence, a Euroclear Company, owns and operates the Euroclear Quantessence platform.
    <br><br>
    Peter De Clercq, CEO of Quantessence, said: "Partnering with Phoenix has been a privilege. The Standard Life Smoothed Return Pension Fund holds a significant position in our range, complementing individualised savings and income products, all supported by the Euroclear Quantessence platform."
    <br><br>
    Jeffrey Baker, Head of Structuring at Phoenix Group, expressed his enthusiasm for the smoothed product launch, highlighting that, "the collaboration with Quantessence has enabled us to deliver the product promptly and efficiently."
    `,
  },
  {
    slug: 'hilbert-blackrock',
    imgSrc: Article2,
    imgAlt: 'article2',
    date: '17th July 2023',
    title: 'Présentation Quantessence - Hilbert - BlackRock',
    descriptionfull:'Dans cette présentation, Quantessence, Hilbert et BlackRock parlent de leur collaboration récente qui a permis le lancement d’un PER nouvelle génération, basé sur un portefeuille modèle de BlackRock. Quantessence, Hilbert and BlackRock announce the launch of a new generation French savings product based on a model portfolio managed by BlackRock.',
    videoUrl: 'https://www.youtube.com/watch?v=gcndQJBO5cM',
  },
  {
    slug: 'mass-customisation',
    imgSrc: Article3,
    imgAlt: '',
    date: '5th April 2023',
    title: 'Mass customisation in guaranteed investment products',
    descriptionfull:'Peter De Clercq joins Christian Probst, Head of Capital Markets Solutions Structuring at Munich Re, to discuss how technology enables mass customisation of financial products. They discuss the benefits, the challenges and the way Quantessence helps financial institutions overcome those challenges.',
    videoUrl: 'https://youtu.be/N9ZmmjGWDfU',

    },
  {
    slug: 'fsma-approval',
    imgSrc: Article4,
    imgAlt: '',
    date: '1st December 2022',
    title: 'Quantessence obtains FSMA Approval',
    description:'Quantessence has obtained regulatory approval as a MiFID investment firm in Belgium, allowing them to perform relevant regulated activities in Belgium and, via passporting, in the whole of the EU.',
    descriptionfull:'Quantessence has obtained regulatory approval as a MiFID investment firm in Belgium, allowing them to perform relevant regulated activities in Belgium and, via passporting, in the whole of the EU. ',
    videoUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7004085526060269568',
  },
  {
    slug: 'technology-transformation',
    imgSrc: Article5,
    imgAlt: 'article5',
    date: '9th November 2022',
    title: 'How is technology transforming the funds industry ?',
    description:'At the International Funds Summit, Peter had the pleasure of sharing the stage with leading fund managers and fund administration...',
    descriptionfull:'At the International Funds Summit, Peter will have the pleasure of sharing the stage with Rémi Cuinat, Generali, Chris Muyldermans, KBC, Hirander Misra, GMEX, Thomas Kutschera, Axovision, Florian Peter, Greenfield One and Christina Orfanidou, Deloitte.',
    videoUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:6995415943178838016', //linkedin-url
  },
  {
    slug: 'munich-re',
    imgSrc: Article7,
    imgAlt: '',
    date: '4th August 2022',
    title: 'Munich Re and Quantessence lead product innovation in France',
    description:
      'Stéphane Vanadia, Munich Re Markets, and Peter De Clercq, announce the launch of the first protected PER in France.',
    descriptionfull: '',
    videoUrl: 'https://vimeo.com/736521084',
  },
  {
    slug: 'generali-launches-individualised-products-in-spain',
    imgSrc: Article8,
    imgAlt: '',
    date: '23rd March 2022',
    title: 'Generali launches individualised products in Spain',
    description:
      'Chiara Scalone, Product Development Specialist, discusses Generali Evolución Ahorro, an innovative savings product that runs on the Euroclear Quantessence platform.',
    descriptionfull:
      'Chiara Scalone, Product Development Specialist, discusses Generali Evolución Ahorro, an innovative savings product that runs on the Euroclear Quantessence platform.',
    videoUrl: 'https://youtu.be/03gbbHBfVoY',
    },
  {
    slug: 'life-insurance',
    imgSrc: Article6,
    imgAlt: '',
    date: '17th January 2022',
    title: 'Quantessence: Tech Solutions in Life Insurance',
    description:
      'In this interview with InsureTV, Peter De Clercq, CEO of Quantessence, speaks to how technology can provide a solution to...',
    descriptionfull:
      'In this interview with InsureTV, Peter De Clercq, CEO of Quantessence, speaks to how technology can provide a solution to the increased need for personalised products within life insurance.',
    videoUrl: 'https://www.youtube.com/watch?v=9YbltMjX1y0',
  },
];

export default articles;
