import React from 'react';
import Title from '../Title';
import ValuesCards from './ValuesCards';

const ValuesSection = () => {
  return (
    <section>
      <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
        <Title>Value Proposition</Title>
        <ValuesCards />
      </div>
    </section>
  );
}

export default ValuesSection;