import Sales from '../assets/careers/financial-sales.jpg';
import Business from '../assets/careers/business-analyst.jpg';
const jobs = [
  {
    id: 1,
    slug: 'financial-sales',
    category: 'Sales',
    title: 'Financial Sales',
    image: Sales,
    description: {
      location: 'Quantessence Brussels, Brussels Region, Belgium (Hybrid)',
      intro: `Quantessence, a Fintech firm within Euroclear, enables financial institutions to offer next-generation savings products, primarily for life insurance companies, banks, asset managers, and investment banks. As our services rapidly grow across Europe, we invite you to join us.`,
      position: 'Financial Solutions Sales',
      role: [
        '• Identify and approach potential clients, primarily large financial institutions across Europe and globally.',
        '• Collaborate with team members to design solutions that meet clients needs.',
        '• Prepare and deliver compelling presentations and proposals to prospective clients.',
        '• Develop sales materials, primarily using PowerPoint.',
        '• Assist in creating marketing materials such as brochures, videos, web design, and social media content.',
        '• Organise and participate in client events and conferences, work on RFPs, and help design dashboards.',
        '• Conduct market research to identify new business opportunities and stay informed about industry trends.',
        '• Build and maintain strong client relationships, providing ongoing support and addressing their needs.',
        '• Work closely with internal teams such as Financial Engineering, Technology, Project Management, Compliance, and Risk to ensure solutions are viable, attractive, and compliant.',
      ],
      experience: [
        '• Proven track record as a solutions or structured products sales professional in the financial sector, preferably in investment banking.',
        '• Extensive experience and understanding of financial products, including financial derivatives.',
        '• In-depth knowledge of banking, insurance, investment, and other financial services.',
        '• Proficiency in tools like PowerPoint, Excel, and Word.',
        '• Excellent communication and interpersonal skills.',
        '• Self-starter, willing to take initiative.',
      ],
      qualifications: [
        '• Master’s degree in a relevant field.',
        '• Proficiency in English.',
        '• Native speaker of German, French, Spanish, or another continental European language.',
      ],
      additional_info: `The role is based in Brussels, with travel across the EU and to our London and Prague offices. We offer relocation or commuting options from neighbouring countries. You will gain exposure to innovative financial technology and work with high-calibre partners in insurance companies, investment banks, and asset managers across Europe and the UK. We offer an inclusive work environment with high work ethics, where all individuals are respected, and all contributions valued.`,
      jobUrl: 'https://www.linkedin.com/jobs/view/4033444346',
    },
  },
  {
    id: 2,
    slug: 'business-analyst',
    category: 'Business',
    title: 'Business Solutions Analyst',
    image: Business,
    description: {
      location: 'Quantessence Brussels, Brussels Region, Belgium (Hybrid)',
      intro: `Quantessence, a Fintech firm within Euroclear, enables financial institutions to offer next-generation savings products, primarily for life insurance companies, banks, asset managers, and investment banks. As our services rapidly grow across Europe, we invite you to join us.`,
      position: 'Business Solutions Analyst',
      role: [
        '• Identify and document business requirements for financial institutions delivering innovative financial products.',
        '• Collaborate with team members to design solutions and technical requirements.',
        '• Assist in project planning and execution.',
        '• Participate in testing phases to ensure solutions align with business requirements.',
        '• Deliver dashboards (in Tableau) and reporting tools to clients.',
      ],
      experience: [
        '• Proven track record as a solutions architect/business analyst in the financial sector.',
        '• Extensive experience and understanding of financial products, including financial derivatives.',
        '• In-depth knowledge of investment, insurance, banking, and other financial services.',
        '• Experience with Tableau.',
        '• Proficiency in data analysis tools like Excel, SQL, and business intelligence software.',
        '• Experience with system implementation.',
        '• Ability to create detailed reports, dashboards, and visualisations to support decision-making.',
        '• Familiarity with project management tools like JIRA or MS Project.',
      ],
      qualifications: [
        '• Master’s degree in finance, business administration, information systems, or a related field (diverse educational backgrounds valued).',
        '• Proficiency in English.',
        '• Knowledge of Spanish, German, Italian, or French is a plus.',
      ],
      additional_info: `The role is based in Brussels, with travel across the EU and to our London and Prague offices. We offer relocation or commuting options from neighbouring countries. You will gain exposure to innovative financial technology and work with high-calibre partners in insurance companies, investment banks, and asset managers across Europe and the UK. We offer an inclusive work environment with high work ethics, where all individuals are respected, and all contributions valued.`,
      jobUrl: 'https://www.linkedin.com/jobs/view/4033443469',
    },
  },
];

export default jobs;