const privacycookie = {
  sections: [
    {
      heading: '1. Privacy and Cookie Policy',
      paragraphs: [
        `Quantessence Financial is referred to in this notice as ‘Quantessence’, ‘we’ or ‘our’.`,
        `Like all your assets in Quantessence, we aim to treat your personal information with respect and responsibility. This privacy and cookie notice describes the standards we apply to your personal data.`,
        `It discloses the information practices for the Quantessence website (quantessence.com) and other marketing channels, including what type of information is gathered and tracked, how the information is used, and with whom the information is shared.`,
        `The notice also explains how we use cookies and what your rights are to control our use of them.`,
      ],
    },
    {
      heading: '2. Privacy policy',
      subSections: [
        {
          heading: '2.1. What is personal data?',
          paragraphs: [
            `‘Personal data’ is all information from which an individual is identified or identifiable, whether directly or indirectly.`,
            `Personal data is not limited to an individual’s private details, but also concerns his or her professional details.`,
            `Personal data may be a name, location data, online identifier, etc.`,
          ],
        },
        {
          heading: '2.2. Where does your personal data come from?',
        },
        {
          heading: '2.2.1. Information we collect from you',
          paragraphs: [
            `Along with information you provide to us directly when you register or subscribe to our services offered through our websites, we may collect the following personal data about you, when you visit our websites:`,
            `• Connection Data`,
            `• your IP address`,
            `• Information about your devices such as information contained in HTTP headers or other internet transfer protocol signals, browser or device type and version, operating system, user-agent strings and information about or from the presence or use of “apps” on your mobile devices, screen resolution, and your preferred language.`,
            `Some of this information is collected using cookies and similar tracking technology.`,
            `You can opt out of cookies that are not required to enable core site functionality.`,
          ],
        },
        {
          heading: '2.2.2. Personal data we receive from other sources',
          paragraphs: [
            `From time to time, we receive personal data about you from third party sources.`,
            `• We receive personal data from our partners or conference organisers you have authorised to transfer your personal data to us. These parties are responsible for managing their use of personal data.`,
          ],
        },
      ],
    },
    {
      heading: '2.3. Which personal data do we collect and for which purpose?',
      subSections: [
        {
          heading: '2.3.1. Purpose of the processing',
          paragraphs: [`Quantessence newsletter sign up.`],
        },
        {
          heading: '2.3.2. Types of personal data',
        },
        {
          heading: '2.3.2.1. General',
          paragraphs: [
            `• business card information`,
            `• information on your company`,
            `• connection data`,
          ],
        },
        {
          heading: '2.3.2.2. Tailor marketing to your needs',
          paragraphs: [
            `• business card information`,
            `• information on your company`,
            `• connection data`,
            `• behavioural data`,
          ],
        },
        {
          heading: '2.3.2.3. Website analytics',
          paragraphs: [
            `• information on your company`,
            `• connection data`,
            `• cbehavioural data`,
          ],
        },
        {
          heading: '2.3.2.4. Respond to your requests',
          paragraphs: [
            `• business card information`,
            `• information on your company`,
            `• connection data`,
            `• behavioural data`,
          ],
        },
        {
          heading: '2.3.2.5.  Website experience',
          paragraphs: [
            `Personalized content on our websites improvesthe quality and facilitate the use of our websites:`,
            `• business card information`,
            `• information on your company`,
            `• connection data`,
            `• behavioural data`,
          ],
        },
      ],
    },
    {
      heading:
        '2.4. What is the legal basis for processing your personal data?',
      paragraphs: [
        `We will normally only process personal data from you when`,
        `• we need the personal data to carry out a contract with you;`,
        `• the processing is in our legitimate interests (and not outweighed by your data protection interests or fundamental rights and freedoms);`,
        `• we have obtained your explicit consent to the processing of your personal data.`,
      ],
    },
    {
      heading: '2.5. How long is your personal data kept?',
      paragraphs: [
        `We will retain your personal data:`,
        `• until you request us to remove your personal data from our marketing database (e.g. to honour opt-outs); or`,
        `• as long as necessary to perform our contractual engagements with you`,
        `In addition, we may retain personal data from closed accounts to comply with national laws, prevent fraud, collect any amounts owed, resolve disputes, assist with governmental investigation or take any actions permitted or required by applicable national laws.`,
      ],
    },
    {
      heading: '2.6. Who might Quantessence share your personal data with?',
      paragraphs: [
        `• We transfer your personal data within the Quantessence group for purposes consistent with this Privacy & Cookie Notice. We take measures to restrict access to staff who have a legitimate business need to see your data.`,
        `• Quantessence may also provide your personal data to public bodies such as regulatory, government agency, court or other third parties such as but not limited to, the Police Authorities, the Financial Supervisory Authorities and the Tax Agencies, where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your interests or those of any other person.`,
        `• We also disclose your personal data to our third party vendors, service providers and partners who provide data processing services to us. This may be in connection with the services they provide to us, including to support us in areas such as HR, IT platform management or support services, infrastructure and application services... These service providers may change over time, but our commitment to using trusted vendors will not. We will always work with third parties who will handle your personal data in a way that aligns with this Notice and the GDPR. We have further inserted adequate contractual clauses with these third parties to ensure that your personal data remains protected. Further information can be provided upon request.`,
        `• We also disclose your personal data with our auditors, advisors, legal representatives and similar agents in connection with the advisory services they provide to us for legitimate business purposes and under contractual prohibition of using the personal data for any other purpose.`,
      ],
    },
    {
      heading: '2.7.  International data transfers',
      paragraphs: [
        `Our group companies and third party contractors and consultants operate around the world. This means that we may process personal data outside the European Economic area (EEA) or United Kingdom (UK). However, if it would occur, we take appropriate safeguards to require that your personal data will remain protected in accordance with this notice. These include implementing data protection clauses and EU Standard Contractual Clauses with our third party vendors, contractors and consultants as well as with our group entities.`,
      ],
    },
    {
      heading: '2.8. What are your rights with respect to your personal data?',
      subSections: [
        {
          heading: '2.8.1. The right to be informed',
          paragraphs: [
            `Quantessence is publishing this Privacy Notice to keep you informed as to what we do with your personal data. We strive to be transparent about how we use your personal data.`,
          ],
        },
        {
          heading: '2.8.2. The right to access',
          paragraphs: [
            `You have the right to access your personal data. Please contact Quantessence if you wish to access the personal data Quantessence holds about via complianceteam@quantessence.com.`,
          ],
        },
        {
          heading: '2.8.3. The right to rectification',
          paragraphs: [
            `If any data Quantessence holds about you is inaccurate, not complete or up to date, you have the right to ask us to rectify or update it. If such personal data has been disclosed to a third party in accordance with this Privacy Notice, then we shall also ask them to rectify or update your personal data. Please contact Quantessence if you need us to rectify or update your information.`,
          ],
        },
        {
          heading: '2.8.4. The right to erasure',
          paragraphs: [
            `This is sometimes called ‘the right to be forgotten’. If you want to request Quantessence to erase all your personal data and we do not have a legal ground to continue to process and hold it, please contact Quantessence.`,
          ],
        },
        {
          heading: '2.8.5. The right to restrict processing',
          paragraphs: [
            `In certain circumstances, you have the right to ask Quantessence to restrict how we process your personal data. This means we are permitted to store the data but not further process it. If you want us to restrict processing of your personal data, please contact Quantessence.`,
          ],
        },
        {
          heading: '2.8.6. The right to data portability',
          paragraphs: [
            `In certain circumstances, Quantessence allows you to obtain the personal data we hold about you in a structured, commonly used and machine-readable format and to transmit those personal data to another controller without any hindrance. Please contact Quantessence if you want information on how to transfer your personal data elsewhere. This right only applies to personal data that you have provided to us as the data controller. The personal data must be processed by us on the basis of your consent or for the performance of a contract and processing of your personal data must be carried out by automated means.`,
          ],
        },
        {
          heading: '2.8.7. The right to object',
          paragraphs: [
            `In certain circumstances, you have the right to object to Quantessence processing your personal data. If you wish to object, please contact Quantessence.`,
          ],
        },
        {
          heading: '2.8.8. The right to withdraw consent',
          paragraphs: [
            `If you have given us your consent to process your personal data but would change your mind later, you have the right to withdraw your consent at any time, and Quantessence will then stop processing your personal data. If you have any further question, please contact Quantessence via complianceteam@quantessence.com.`,
          ],
        },
        {
          heading: '2.8.9. The right to opt-out of marketing communications',
          paragraphs: [
            `You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the unsubscribe link in the marketing e-mails we send you. To opt-out of other forms of marketing (such as postal marketing or telemarketing), please contact Quantessence via complianceteam@quantessence.com.`,
          ],
        },
        {
          heading: '2.8.10. The right to complain to a supervisory authority',
          paragraphs: [
            `You have the right to complain to your local data protection authority about our collection and use of your personal data if you feel that Quantessence has not sufficiently addressed any concern of complaint. For more information, please contact your local data protection authority.`,
          ],
        },
        {
          heading: '2.9. What about data security and quality?',
          paragraphs: [
            `We protect your personal data and maintain its quality. We implement appropriate measures and processes that are designed to provide a level of security appropriate to the risk of processing your personal data to help us to keep your personal data secure and to maintain its quality. In order to keep your personal data secure, we have implemented a number of security measures, including:`,
            `• Secure operating environments – We store your data in secure operating environments and only accessible to Quantessence employees, agents and contractors on a need-to-know basis. Quantessence also follows generally accepted industry standards in this respect.`,
            `• Encryption – We use industry-standard encryption to provide protection for information that is transmitted to us via the Websites. `,
          ],
        },
        {
          heading: '2.10. Links to non-Quantessence sites',
          paragraphs: [
            `The Quantessence website contains links to other websites. Quantessence is not responsible for the privacy practices or the content of those other websites.`,
          ],
        },
        {
          heading: '2.11. What if you choose not to give us personal data?',
          paragraphs: [
            `If you do not want to give us your personal data and the personal data is necessary in order to:`,
            `• enter a contract`,
            `• supply services`,
            `• provide you with further information on our services`,
            `• assess your job application`,
            `then we will not be able to enter into that contract or provide the requested services or information or consider you for a job.`,
          ],
        },
      ],
    },
    {
      heading: '3. Cookies',
      subSections: [
        {
          heading: '3.1. What are cookies and how do we use them?',
          paragraphs: [
            `We collect the information we mentioned in the previous paragraphs through the use of various technologies, including “cookies”.Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.`,
          ],
        },
        {
          heading: '3.2. Why do we use cookies?',
          paragraphs: [
            `We use cookies to guarantee the proper functioning of our websites, to make our sites easier to use and to provide you with a more personalised experience based on your interests and needs. Quantessence cookies may also be used to offer you services or to invite you for certain events. We also use cookies to collect anonymous statistics that enable us to understand how and how frequent you use our websites and to help us provide better customer service.`,
          ],
        },
        {
          heading: '3.3. Categories of cookies used',
        },
        {
          heading: '3.3.1. Strictly necessary cookies',
          paragraphs: [
            `These are cookies that are required for the proper functioning of our website. They include, for example, cookies that enable you to log into the secure areas of our website.`,
          ],
        },
        {
          heading: '3.3.2. Functional and performance cookies',
          paragraphs: [
            `These cookies allow the website to remember the choices that users make (e.g. user name, language or the region the user is currently in) to provide enhanced and personalised features. They also collect information about how visitors use our website, e.g. which pages visitors go to most often, the time spent on these pages, any problems they would face. These cookies thus keep record of certain of your preferences and allows us to enhance the performance of our website.`,
          ],
        },
        {
          heading: '3.3.3. Analytical cookies',
          paragraphs: [
            `These cookies allow us to recognise and count the number of visitors to our website. The result thereof are anonymous statistics that enable us to know how often you use our website which will help us improve customer service.`,
          ],
        },
        {
          heading: '3.4. Third party cookies',
          paragraphs: [
            `Third party cookies are stored by a different domain to the visited page's domain. This can happen when the webpage references a file, such as JavaScript, located outside its domain. All the cookies used by the website are listed <a href="/cookielist" style="color: #A02939; text-decoration: underline;">here</a>.`
          ],
        },        
        {
          heading: '3.5. How do you consent the use of cookies?',
          paragraphs: [
            `When entering this website, you were immediately introduced to the Quantessence privacy and cookie notice. We also explained how you can consent to the use of cookies, as you have the right to accept, manage or reject the cookies we use. Please be aware that refusing the use of cookies may have an impact on the functionality of our websites. Some of our services, including logging into our secured area, require cookies in your browser to view and use them.`,
          ],
        },
        {
          heading: '3.6. How can you manage cookies in your browser?',
          paragraphs: [
            `You always have the possibility to adjust your browser settings to accept or block cookies, or to notify you before you receive a cookie. Each browser has a different procedure for disabling the use of cookies. The Help function within your browser tells you how you can modify your settings. Alternatively, you may wish to visit http://www.aboutcookies.org/ which contains comprehensive information on how to do this on a wide variety of browsers. You will also find details on this site on how to erase cookies from your computer or mobile device as well as more general information about cookies.`,
          ],
        },
        {
          heading:
            '3.7. You can opt-out of analytical cookies by saving your preference on the pop up that appear on your page',
          paragraphs: [
            `Please refer to the cookie pop up to Customize, Accept All or Reject All cookies according to your preference.`,
          ],
        },
      ],
    },
    {
      heading: '4. Updates to this privacy and cookie notice',
      paragraphs: [
        `We may update this privacy and cookie notice from time to time in response to a changing legal, technical or business environment. When we update our privacy and notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material privacy notice changes if and where this is required by law. You can see when this privacy and cookie notice was last updated by checking the last updated date displayed at the top of this privacy notice.`,
      ],
    },
    {
      heading: '5. Contact Details',
      paragraphs: [
        `If you have any questions or concerns regarding our privacy practices, this notice, or our role under the GDPR, please address them to the Quantessence Privacy Officer using the details below:`,
      ],
      contacts: [
        {
          entity: 'Quantessence Financial NV/SA',
          officer: 'Privacy Officer EU GDPR',
          address: '21, Avenue du Boulevard, 5th floor, 1210 Brussels, Belgium',
          email: 'complianceteam@quantessence.com',
        },
        {
          entity: 'Quantessence Financial Ltd',
          officer: 'Privacy Officer UK GDPR',
          address: '33 Cannon Street, London, EC4M 5SB',
          email: 'complianceteam@quantessence.com',
        },
      ],
    },
  ],
};

export default privacycookie;
