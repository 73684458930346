import React from 'react';
import IllustrationSection from '../components/Illustrations/IllustrationSection';

function Illustrations() {
  return (
    <>
      <IllustrationSection />
    </>
  );
}

export default Illustrations;